import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as GQLTypes from "@holibob-packages/graphql-types";

const defaultOptions = {} as const;
export type BrandColourPresetFragment = { __typename: "BrandColourPreset"; main: string; contrast: string };

export type BrandForThemeFragment = {
    __typename: "Brand";
    primaryFont: string | null;
    headerLogoPaddingTop: number | null;
    headerLogoPaddingBottom: number | null;
    airiness: number;
    roundness: number;
    specialFont:
        | {
              __typename: "FontCustom";
              bold: { __typename: "Asset"; uri: string } | null;
              boldItalic: { __typename: "Asset"; uri: string } | null;
              medium: { __typename: "Asset"; uri: string } | null;
              mediumItalic: { __typename: "Asset"; uri: string } | null;
              regular: { __typename: "Asset"; uri: string };
              regularItalic: { __typename: "Asset"; uri: string } | null;
          }
        | { __typename: "FontExternal"; name: string }
        | null;
    headerColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
    secondaryColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
    footerColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
    primaryColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
    primaryButtonColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
    secondaryButtonColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
    spotColours: Array<{
        __typename: "BrandSpotColour";
        shade40: string;
        shade70: string;
        shade100: string;
        shade400: string;
        shade700: string;
        index: number;
    }>;
};

type Font_FontCustom_Fragment = {
    __typename: "FontCustom";
    bold: { __typename: "Asset"; uri: string } | null;
    boldItalic: { __typename: "Asset"; uri: string } | null;
    medium: { __typename: "Asset"; uri: string } | null;
    mediumItalic: { __typename: "Asset"; uri: string } | null;
    regular: { __typename: "Asset"; uri: string };
    regularItalic: { __typename: "Asset"; uri: string } | null;
};

type Font_FontExternal_Fragment = { __typename: "FontExternal"; name: string };

export type FontFragment = Font_FontCustom_Fragment | Font_FontExternal_Fragment;

export type FontCustomFragment = {
    __typename: "FontCustom";
    bold: { __typename: "Asset"; uri: string } | null;
    boldItalic: { __typename: "Asset"; uri: string } | null;
    medium: { __typename: "Asset"; uri: string } | null;
    mediumItalic: { __typename: "Asset"; uri: string } | null;
    regular: { __typename: "Asset"; uri: string };
    regularItalic: { __typename: "Asset"; uri: string } | null;
};

export type BookingFragment = {
    __typename: "Booking";
    id: string;
    code: string;
    leadPassengerName: string | null;
    partnerName: string | null;
    state: GQLTypes.BookingStateType;
    paymentState: GQLTypes.BookingPaymentStateType | null;
    isSandboxed: boolean;
    isWorkflowProcessing: boolean;
    workflowInitialisedAt: string | null;
    isComplete: boolean;
    reference: string | null;
    saleCurrency: string | null;
    canCommit: boolean | null;
    isQuestionsComplete: boolean | null;
    voucherUrl: string | null;
    canCancel: boolean | null;
    createdAt: string | null;
    voucherCreatedAt: string | null;
    bookingUrl: string;
    emailSummarySentAt: string | null;
    termAndCondition: string | null;
    useLifecycleManager: boolean;
    isPendingCommit: boolean | null;
    consumerTrip: { __typename: "ConsumerTripBasic"; id: string; placeId: string | null } | null;
    cancellationEffectiveRefundAmount: {
        __typename: "BasicPrice";
        amount: number;
        currency: string;
        formattedText: string;
    } | null;
    contact: { __typename: "BookingContact"; email: string; phone: string; name: string } | null;
    totalPrice: {
        __typename: "Price";
        gross: number;
        grossFormattedText: string;
        netFormattedText: string;
        commissionFormattedText: string;
        currency: string;
    };
    outstandingConsumerAmount: { __typename: "BasicPrice"; amount: number; currency: string; formattedText: string };
    questionList: {
        __typename: "BookingQuestionList";
        nodes: Array<{
            __typename: "BookingQuestion";
            id: string;
            bookingId: string;
            type: string | null;
            label: string | null;
            helper: string | null;
            autoCompleteValue: string | null;
            placeholder: string | null;
            dataType: string | null;
            dataFormat: string | null;
            defaultValue: string | null;
            isFromOptions: boolean;
            isSelectMultiple: boolean;
            isRequired: boolean;
            answerValue: string | null;
            answerFile: string | null;
            answerFormattedText: string | null;
            availableOptions: Array<{
                __typename: "AvailableOptions";
                label: string | null;
                value: string | null;
            } | null>;
            answerFileAsset: { __typename: "Asset"; url: string } | null;
        }>;
    };
    availabilityList: {
        __typename: "BookingAvailabilityList";
        nodes: Array<{
            __typename: "BookingAvailability";
            id: string;
            date: string | null;
            startAt: string | null;
            createdAt: string;
            type: GQLTypes.BookingAvailabilityType;
            timeZone: string;
            productName: string;
            productId: string;
            refreshStatus: GQLTypes.BookingAvailabilityRefreshStatus | null;
            canCancel: boolean;
            canRevertCancellation: boolean;
            canForceCancel: boolean;
            canEdit: boolean;
            canRevertEdit: boolean;
            canAmend: boolean;
            code: string;
            saleCurrency: string | null;
            bookingId: string;
            originBookingAvailabilityId: string | null;
            voucherUrl: string | null;
            isActive: boolean;
            isConfirmed: boolean;
            isQuestionsComplete: boolean;
            state: GQLTypes.BookingAvailabilityProcessState;
            parent: {
                __typename: "BookingAvailabilitySimple";
                refreshStatus: GQLTypes.BookingAvailabilityRefreshStatus | null;
                totalPrice: { __typename: "Price"; grossFormattedText: string };
            } | null;
            totalPrice: {
                __typename: "Price";
                grossFormattedText: string;
                netFormattedText: string;
                commissionFormattedText: string;
                gross: number;
                currency: string;
            };
            product: {
                __typename: "Product";
                id: string;
                name: string | null;
                availabilityType: GQLTypes.AvailabilityType;
                previewImage: { __typename: "ProductImage"; id: string } | null;
                cancellationPolicy: {
                    __typename: "ProductCancellationPolicy";
                    isCancellable: boolean | null;
                    penaltyList: {
                        __typename: "ProductCancellationPolicyPenaltyList";
                        nodes: Array<{ __typename: "ProductCancellationPenalty"; formattedText: string | null }>;
                    };
                };
            };
            optionList: {
                __typename: "BookingAvailabilityOptionList";
                nodes: Array<{
                    __typename: "BookingAvailabilityOption";
                    id: string;
                    isAnswered: boolean;
                    label: string | null;
                    level: number | null;
                    helper: string | null;
                    placeholder: string | null;
                    dataType: string | null;
                    answerValue: string | null;
                    answerFormattedText: string | null;
                    type: string | null;
                    availableOptions: Array<{
                        __typename: "AvailableOptions";
                        label: string | null;
                        value: string | null;
                    } | null>;
                }>;
            };
            questionList: {
                __typename: "BookingAvailabilityQuestionList";
                nodes: Array<{
                    __typename: "BookingAvailabilityQuestion";
                    id: string;
                    bookingId: string;
                    label: string | null;
                    helper: string | null;
                    placeholder: string | null;
                    dataType: string | null;
                    dataFormat: string | null;
                    defaultValue: string | null;
                    isFromOptions: boolean;
                    isSelectMultiple: boolean;
                    isRequired: boolean;
                    answerValue: string | null;
                    answerFile: string | null;
                    answerFormattedText: string | null;
                    autoCompleteValue: string | null;
                    availableOptions: Array<{
                        __typename: "AvailableOptions";
                        label: string | null;
                        value: string | null;
                    } | null>;
                    answerFileAsset: { __typename: "Asset"; url: string } | null;
                }>;
            };
            personList: {
                __typename: "BookingAvailabilityPersonList";
                nodes: Array<{
                    __typename: "BookingAvailabilityPerson";
                    id: string;
                    pricingCategoryLabel: string | null;
                    isQuestionsComplete: boolean;
                    totalPrice: { __typename: "Price"; grossFormattedText: string; netFormattedText: string };
                    questionList: {
                        __typename: "BookingAvailabilityPersonQuestionList";
                        nodes: Array<{
                            __typename: "BookingAvailabilityPersonQuestion";
                            id: string;
                            bookingId: string;
                            label: string | null;
                            helper: string | null;
                            placeholder: string | null;
                            dataType: string | null;
                            dataFormat: string | null;
                            defaultValue: string | null;
                            isFromOptions: boolean;
                            isSelectMultiple: boolean;
                            isRequired: boolean;
                            answerValue: string | null;
                            answerFile: string | null;
                            answerFormattedText: string | null;
                            autoCompleteValue: string | null;
                            availableOptions: Array<{
                                __typename: "AvailableOptions";
                                label: string | null;
                                value: string | null;
                            } | null>;
                            answerFileAsset: { __typename: "Asset"; url: string } | null;
                        }>;
                    };
                }>;
            };
            cancellationState: {
                __typename: "BookingAvailabilityCancellationState";
                effectiveRefundState: GQLTypes.BookingAvailabilityCancellationEffectiveRefundState;
                effectiveRefundAmount: { __typename: "BasicPrice"; amount: number; formattedText: string };
                effectivePenalty: {
                    __typename: "CancellationPenalty";
                    type: GQLTypes.CancellationPenaltyType;
                    relativeTo: GQLTypes.CancellationPenaltyRelativeToType;
                    duration: string | null;
                    refundPercentage: number | null;
                    amount: number | null;
                    amountType: string | null;
                    amountCurrency: string | null;
                    formattedText: string | null;
                };
            } | null;
            cancellationPolicy: {
                __typename: "BookingAvailabilityCancellationPolicy";
                isCancellable: boolean;
                penaltyList: {
                    __typename: "BookingAvailabilityCancellationPolicyPenaltyList";
                    nodes: Array<{
                        __typename: "ProductCancellationPenalty";
                        formattedText: string | null;
                        ordinalPosition: number | null;
                    }>;
                };
            };
            originBookingAvailability: {
                __typename: "BookingAvailabilitySimple";
                id: string;
                startAt: string | null;
                endAt: string | null;
                createdAt: string;
                timeZone: string;
                type: GQLTypes.BookingAvailabilityType;
                productName: string;
                state: GQLTypes.BookingAvailabilityProcessState;
                totalPrice: { __typename: "Price"; grossFormattedText: string };
                personList: {
                    __typename: "BookingAvailabilityPersonList";
                    nodes: Array<{
                        __typename: "BookingAvailabilityPerson";
                        id: string;
                        pricingCategoryLabel: string | null;
                        isQuestionsComplete: boolean;
                        totalPrice: { __typename: "Price"; grossFormattedText: string; netFormattedText: string };
                        questionList: {
                            __typename: "BookingAvailabilityPersonQuestionList";
                            nodes: Array<{
                                __typename: "BookingAvailabilityPersonQuestion";
                                id: string;
                                bookingId: string;
                                label: string | null;
                                helper: string | null;
                                placeholder: string | null;
                                dataType: string | null;
                                dataFormat: string | null;
                                defaultValue: string | null;
                                isFromOptions: boolean;
                                isSelectMultiple: boolean;
                                isRequired: boolean;
                                answerValue: string | null;
                                answerFile: string | null;
                                answerFormattedText: string | null;
                                autoCompleteValue: string | null;
                                availableOptions: Array<{
                                    __typename: "AvailableOptions";
                                    label: string | null;
                                    value: string | null;
                                } | null>;
                                answerFileAsset: { __typename: "Asset"; url: string } | null;
                            }>;
                        };
                    }>;
                };
                optionList: {
                    __typename: "BookingAvailabilityOptionList";
                    recordCount: number;
                    nodes: Array<{
                        __typename: "BookingAvailabilityOption";
                        id: string;
                        isAnswered: boolean;
                        label: string | null;
                        level: number | null;
                        helper: string | null;
                        placeholder: string | null;
                        dataType: string | null;
                        answerValue: string | null;
                        answerFormattedText: string | null;
                        type: string | null;
                        availableOptions: Array<{
                            __typename: "AvailableOptions";
                            label: string | null;
                            value: string | null;
                        } | null>;
                    }>;
                };
            } | null;
        }>;
    };
};

export type BookingAvailabilityFragment = {
    __typename: "BookingAvailability";
    id: string;
    date: string | null;
    startAt: string | null;
    createdAt: string;
    type: GQLTypes.BookingAvailabilityType;
    timeZone: string;
    productName: string;
    productId: string;
    refreshStatus: GQLTypes.BookingAvailabilityRefreshStatus | null;
    canCancel: boolean;
    canRevertCancellation: boolean;
    canForceCancel: boolean;
    canEdit: boolean;
    canRevertEdit: boolean;
    canAmend: boolean;
    code: string;
    saleCurrency: string | null;
    bookingId: string;
    originBookingAvailabilityId: string | null;
    voucherUrl: string | null;
    isActive: boolean;
    isConfirmed: boolean;
    isQuestionsComplete: boolean;
    state: GQLTypes.BookingAvailabilityProcessState;
    parent: {
        __typename: "BookingAvailabilitySimple";
        refreshStatus: GQLTypes.BookingAvailabilityRefreshStatus | null;
        totalPrice: { __typename: "Price"; grossFormattedText: string };
    } | null;
    totalPrice: {
        __typename: "Price";
        grossFormattedText: string;
        netFormattedText: string;
        commissionFormattedText: string;
        gross: number;
        currency: string;
    };
    product: {
        __typename: "Product";
        id: string;
        name: string | null;
        availabilityType: GQLTypes.AvailabilityType;
        previewImage: { __typename: "ProductImage"; id: string } | null;
        cancellationPolicy: {
            __typename: "ProductCancellationPolicy";
            isCancellable: boolean | null;
            penaltyList: {
                __typename: "ProductCancellationPolicyPenaltyList";
                nodes: Array<{ __typename: "ProductCancellationPenalty"; formattedText: string | null }>;
            };
        };
    };
    optionList: {
        __typename: "BookingAvailabilityOptionList";
        nodes: Array<{
            __typename: "BookingAvailabilityOption";
            id: string;
            isAnswered: boolean;
            label: string | null;
            level: number | null;
            helper: string | null;
            placeholder: string | null;
            dataType: string | null;
            answerValue: string | null;
            answerFormattedText: string | null;
            type: string | null;
            availableOptions: Array<{
                __typename: "AvailableOptions";
                label: string | null;
                value: string | null;
            } | null>;
        }>;
    };
    questionList: {
        __typename: "BookingAvailabilityQuestionList";
        nodes: Array<{
            __typename: "BookingAvailabilityQuestion";
            id: string;
            bookingId: string;
            label: string | null;
            helper: string | null;
            placeholder: string | null;
            dataType: string | null;
            dataFormat: string | null;
            defaultValue: string | null;
            isFromOptions: boolean;
            isSelectMultiple: boolean;
            isRequired: boolean;
            answerValue: string | null;
            answerFile: string | null;
            answerFormattedText: string | null;
            autoCompleteValue: string | null;
            availableOptions: Array<{
                __typename: "AvailableOptions";
                label: string | null;
                value: string | null;
            } | null>;
            answerFileAsset: { __typename: "Asset"; url: string } | null;
        }>;
    };
    personList: {
        __typename: "BookingAvailabilityPersonList";
        nodes: Array<{
            __typename: "BookingAvailabilityPerson";
            id: string;
            pricingCategoryLabel: string | null;
            isQuestionsComplete: boolean;
            totalPrice: { __typename: "Price"; grossFormattedText: string; netFormattedText: string };
            questionList: {
                __typename: "BookingAvailabilityPersonQuestionList";
                nodes: Array<{
                    __typename: "BookingAvailabilityPersonQuestion";
                    id: string;
                    bookingId: string;
                    label: string | null;
                    helper: string | null;
                    placeholder: string | null;
                    dataType: string | null;
                    dataFormat: string | null;
                    defaultValue: string | null;
                    isFromOptions: boolean;
                    isSelectMultiple: boolean;
                    isRequired: boolean;
                    answerValue: string | null;
                    answerFile: string | null;
                    answerFormattedText: string | null;
                    autoCompleteValue: string | null;
                    availableOptions: Array<{
                        __typename: "AvailableOptions";
                        label: string | null;
                        value: string | null;
                    } | null>;
                    answerFileAsset: { __typename: "Asset"; url: string } | null;
                }>;
            };
        }>;
    };
    cancellationState: {
        __typename: "BookingAvailabilityCancellationState";
        effectiveRefundState: GQLTypes.BookingAvailabilityCancellationEffectiveRefundState;
        effectiveRefundAmount: { __typename: "BasicPrice"; amount: number; formattedText: string };
        effectivePenalty: {
            __typename: "CancellationPenalty";
            type: GQLTypes.CancellationPenaltyType;
            relativeTo: GQLTypes.CancellationPenaltyRelativeToType;
            duration: string | null;
            refundPercentage: number | null;
            amount: number | null;
            amountType: string | null;
            amountCurrency: string | null;
            formattedText: string | null;
        };
    } | null;
    cancellationPolicy: {
        __typename: "BookingAvailabilityCancellationPolicy";
        isCancellable: boolean;
        penaltyList: {
            __typename: "BookingAvailabilityCancellationPolicyPenaltyList";
            nodes: Array<{
                __typename: "ProductCancellationPenalty";
                formattedText: string | null;
                ordinalPosition: number | null;
            }>;
        };
    };
    originBookingAvailability: {
        __typename: "BookingAvailabilitySimple";
        id: string;
        startAt: string | null;
        endAt: string | null;
        createdAt: string;
        timeZone: string;
        type: GQLTypes.BookingAvailabilityType;
        productName: string;
        state: GQLTypes.BookingAvailabilityProcessState;
        totalPrice: { __typename: "Price"; grossFormattedText: string };
        personList: {
            __typename: "BookingAvailabilityPersonList";
            nodes: Array<{
                __typename: "BookingAvailabilityPerson";
                id: string;
                pricingCategoryLabel: string | null;
                isQuestionsComplete: boolean;
                totalPrice: { __typename: "Price"; grossFormattedText: string; netFormattedText: string };
                questionList: {
                    __typename: "BookingAvailabilityPersonQuestionList";
                    nodes: Array<{
                        __typename: "BookingAvailabilityPersonQuestion";
                        id: string;
                        bookingId: string;
                        label: string | null;
                        helper: string | null;
                        placeholder: string | null;
                        dataType: string | null;
                        dataFormat: string | null;
                        defaultValue: string | null;
                        isFromOptions: boolean;
                        isSelectMultiple: boolean;
                        isRequired: boolean;
                        answerValue: string | null;
                        answerFile: string | null;
                        answerFormattedText: string | null;
                        autoCompleteValue: string | null;
                        availableOptions: Array<{
                            __typename: "AvailableOptions";
                            label: string | null;
                            value: string | null;
                        } | null>;
                        answerFileAsset: { __typename: "Asset"; url: string } | null;
                    }>;
                };
            }>;
        };
        optionList: {
            __typename: "BookingAvailabilityOptionList";
            recordCount: number;
            nodes: Array<{
                __typename: "BookingAvailabilityOption";
                id: string;
                isAnswered: boolean;
                label: string | null;
                level: number | null;
                helper: string | null;
                placeholder: string | null;
                dataType: string | null;
                answerValue: string | null;
                answerFormattedText: string | null;
                type: string | null;
                availableOptions: Array<{
                    __typename: "AvailableOptions";
                    label: string | null;
                    value: string | null;
                } | null>;
            }>;
        };
    } | null;
};

export type BookingAvailabilityDomainFragment = {
    __typename: "BookingAvailability";
    id: string;
    date: string | null;
    startAt: string | null;
    createdAt: string;
    type: GQLTypes.BookingAvailabilityType;
    timeZone: string;
    productName: string;
    productId: string;
    refreshStatus: GQLTypes.BookingAvailabilityRefreshStatus | null;
    canCancel: boolean;
    canRevertCancellation: boolean;
    canForceCancel: boolean;
    canEdit: boolean;
    canRevertEdit: boolean;
    canAmend: boolean;
    code: string;
    saleCurrency: string | null;
    bookingId: string;
    useLifecycleManager: boolean;
    originBookingAvailabilityId: string | null;
    voucherUrl: string | null;
    isActive: boolean;
    isConfirmed: boolean;
    isQuestionsComplete: boolean;
    state: GQLTypes.BookingAvailabilityProcessState;
    parent: {
        __typename: "BookingAvailabilitySimple";
        refreshStatus: GQLTypes.BookingAvailabilityRefreshStatus | null;
        totalPrice: { __typename: "Price"; grossFormattedText: string };
    } | null;
    totalPrice: {
        __typename: "Price";
        grossFormattedText: string;
        netFormattedText: string;
        commissionFormattedText: string;
        gross: number;
        currency: string;
    };
    product: {
        __typename: "Product";
        id: string;
        name: string | null;
        availabilityType: GQLTypes.AvailabilityType;
        previewImage: { __typename: "ProductImage"; id: string } | null;
        cancellationPolicy: {
            __typename: "ProductCancellationPolicy";
            isCancellable: boolean | null;
            penaltyList: {
                __typename: "ProductCancellationPolicyPenaltyList";
                nodes: Array<{ __typename: "ProductCancellationPenalty"; formattedText: string | null }>;
            };
        };
    };
    optionList: {
        __typename: "BookingAvailabilityOptionList";
        nodes: Array<{
            __typename: "BookingAvailabilityOption";
            id: string;
            isAnswered: boolean;
            label: string | null;
            level: number | null;
            helper: string | null;
            placeholder: string | null;
            dataType: string | null;
            answerValue: string | null;
            answerFormattedText: string | null;
            type: string | null;
            availableOptions: Array<{
                __typename: "AvailableOptions";
                label: string | null;
                value: string | null;
            } | null>;
        }>;
    };
    bookingQuestionList: {
        __typename: "BookingQuestionList";
        nodes: Array<{
            __typename: "BookingQuestion";
            id: string;
            bookingId: string;
            type: string | null;
            label: string | null;
            helper: string | null;
            autoCompleteValue: string | null;
            placeholder: string | null;
            dataType: string | null;
            dataFormat: string | null;
            defaultValue: string | null;
            isFromOptions: boolean;
            isSelectMultiple: boolean;
            isRequired: boolean;
            answerValue: string | null;
            answerFile: string | null;
            answerFormattedText: string | null;
            availableOptions: Array<{
                __typename: "AvailableOptions";
                label: string | null;
                value: string | null;
            } | null>;
            answerFileAsset: { __typename: "Asset"; url: string } | null;
        }>;
    };
    questionList: {
        __typename: "BookingAvailabilityQuestionList";
        nodes: Array<{
            __typename: "BookingAvailabilityQuestion";
            id: string;
            bookingId: string;
            label: string | null;
            helper: string | null;
            placeholder: string | null;
            dataType: string | null;
            dataFormat: string | null;
            defaultValue: string | null;
            isFromOptions: boolean;
            isSelectMultiple: boolean;
            isRequired: boolean;
            answerValue: string | null;
            answerFile: string | null;
            answerFormattedText: string | null;
            autoCompleteValue: string | null;
            availableOptions: Array<{
                __typename: "AvailableOptions";
                label: string | null;
                value: string | null;
            } | null>;
            answerFileAsset: { __typename: "Asset"; url: string } | null;
        }>;
    };
    personList: {
        __typename: "BookingAvailabilityPersonList";
        nodes: Array<{
            __typename: "BookingAvailabilityPerson";
            id: string;
            pricingCategoryLabel: string | null;
            isQuestionsComplete: boolean;
            totalPrice: { __typename: "Price"; grossFormattedText: string; netFormattedText: string };
            questionList: {
                __typename: "BookingAvailabilityPersonQuestionList";
                nodes: Array<{
                    __typename: "BookingAvailabilityPersonQuestion";
                    id: string;
                    bookingId: string;
                    label: string | null;
                    helper: string | null;
                    placeholder: string | null;
                    dataType: string | null;
                    dataFormat: string | null;
                    defaultValue: string | null;
                    isFromOptions: boolean;
                    isSelectMultiple: boolean;
                    isRequired: boolean;
                    answerValue: string | null;
                    answerFile: string | null;
                    answerFormattedText: string | null;
                    autoCompleteValue: string | null;
                    availableOptions: Array<{
                        __typename: "AvailableOptions";
                        label: string | null;
                        value: string | null;
                    } | null>;
                    answerFileAsset: { __typename: "Asset"; url: string } | null;
                }>;
            };
        }>;
    };
    cancellationState: {
        __typename: "BookingAvailabilityCancellationState";
        effectiveRefundState: GQLTypes.BookingAvailabilityCancellationEffectiveRefundState;
        effectiveRefundAmount: { __typename: "BasicPrice"; amount: number; formattedText: string };
        effectivePenalty: {
            __typename: "CancellationPenalty";
            type: GQLTypes.CancellationPenaltyType;
            relativeTo: GQLTypes.CancellationPenaltyRelativeToType;
            duration: string | null;
            refundPercentage: number | null;
            amount: number | null;
            amountType: string | null;
            amountCurrency: string | null;
            formattedText: string | null;
        };
    } | null;
    cancellationPolicy: {
        __typename: "BookingAvailabilityCancellationPolicy";
        isCancellable: boolean;
        penaltyList: {
            __typename: "BookingAvailabilityCancellationPolicyPenaltyList";
            nodes: Array<{
                __typename: "ProductCancellationPenalty";
                formattedText: string | null;
                ordinalPosition: number | null;
            }>;
        };
    };
    originBookingAvailability: {
        __typename: "BookingAvailabilitySimple";
        id: string;
        startAt: string | null;
        endAt: string | null;
        createdAt: string;
        timeZone: string;
        type: GQLTypes.BookingAvailabilityType;
        productName: string;
        state: GQLTypes.BookingAvailabilityProcessState;
        totalPrice: { __typename: "Price"; grossFormattedText: string };
        personList: {
            __typename: "BookingAvailabilityPersonList";
            nodes: Array<{
                __typename: "BookingAvailabilityPerson";
                id: string;
                pricingCategoryLabel: string | null;
                isQuestionsComplete: boolean;
                totalPrice: { __typename: "Price"; grossFormattedText: string; netFormattedText: string };
                questionList: {
                    __typename: "BookingAvailabilityPersonQuestionList";
                    nodes: Array<{
                        __typename: "BookingAvailabilityPersonQuestion";
                        id: string;
                        bookingId: string;
                        label: string | null;
                        helper: string | null;
                        placeholder: string | null;
                        dataType: string | null;
                        dataFormat: string | null;
                        defaultValue: string | null;
                        isFromOptions: boolean;
                        isSelectMultiple: boolean;
                        isRequired: boolean;
                        answerValue: string | null;
                        answerFile: string | null;
                        answerFormattedText: string | null;
                        autoCompleteValue: string | null;
                        availableOptions: Array<{
                            __typename: "AvailableOptions";
                            label: string | null;
                            value: string | null;
                        } | null>;
                        answerFileAsset: { __typename: "Asset"; url: string } | null;
                    }>;
                };
            }>;
        };
        optionList: {
            __typename: "BookingAvailabilityOptionList";
            recordCount: number;
            nodes: Array<{
                __typename: "BookingAvailabilityOption";
                id: string;
                isAnswered: boolean;
                label: string | null;
                level: number | null;
                helper: string | null;
                placeholder: string | null;
                dataType: string | null;
                answerValue: string | null;
                answerFormattedText: string | null;
                type: string | null;
                availableOptions: Array<{
                    __typename: "AvailableOptions";
                    label: string | null;
                    value: string | null;
                } | null>;
            }>;
        };
    } | null;
    bookingInfoSummary: {
        __typename: "BookingInfoSummary";
        state: GQLTypes.BookingStateType;
        code: string;
        leadPassengerName: string | null;
        partnerExternalReference: string | null;
    };
};

export type BookingAvailabilityOptionFragment = {
    __typename: "BookingAvailabilityOption";
    id: string;
    isAnswered: boolean;
    label: string | null;
    level: number | null;
    helper: string | null;
    placeholder: string | null;
    dataType: string | null;
    answerValue: string | null;
    answerFormattedText: string | null;
    type: string | null;
    availableOptions: Array<{ __typename: "AvailableOptions"; label: string | null; value: string | null } | null>;
};

export type BookingAvailabilityPersonFragment = {
    __typename: "BookingAvailabilityPerson";
    id: string;
    pricingCategoryLabel: string | null;
    isQuestionsComplete: boolean;
    totalPrice: { __typename: "Price"; grossFormattedText: string; netFormattedText: string };
    questionList: {
        __typename: "BookingAvailabilityPersonQuestionList";
        nodes: Array<{
            __typename: "BookingAvailabilityPersonQuestion";
            id: string;
            bookingId: string;
            label: string | null;
            helper: string | null;
            placeholder: string | null;
            dataType: string | null;
            dataFormat: string | null;
            defaultValue: string | null;
            isFromOptions: boolean;
            isSelectMultiple: boolean;
            isRequired: boolean;
            answerValue: string | null;
            answerFile: string | null;
            answerFormattedText: string | null;
            autoCompleteValue: string | null;
            availableOptions: Array<{
                __typename: "AvailableOptions";
                label: string | null;
                value: string | null;
            } | null>;
            answerFileAsset: { __typename: "Asset"; url: string } | null;
        }>;
    };
};

export type BookingAvailabilityPersonQuestionFragment = {
    __typename: "BookingAvailabilityPersonQuestion";
    id: string;
    bookingId: string;
    label: string | null;
    helper: string | null;
    placeholder: string | null;
    dataType: string | null;
    dataFormat: string | null;
    defaultValue: string | null;
    isFromOptions: boolean;
    isSelectMultiple: boolean;
    isRequired: boolean;
    answerValue: string | null;
    answerFile: string | null;
    answerFormattedText: string | null;
    autoCompleteValue: string | null;
    availableOptions: Array<{ __typename: "AvailableOptions"; label: string | null; value: string | null } | null>;
    answerFileAsset: { __typename: "Asset"; url: string } | null;
};

export type BookingAvailabilityProductFragment = {
    __typename: "Product";
    id: string;
    name: string | null;
    availabilityType: GQLTypes.AvailabilityType;
    previewImage: { __typename: "ProductImage"; id: string } | null;
    cancellationPolicy: {
        __typename: "ProductCancellationPolicy";
        isCancellable: boolean | null;
        penaltyList: {
            __typename: "ProductCancellationPolicyPenaltyList";
            nodes: Array<{ __typename: "ProductCancellationPenalty"; formattedText: string | null }>;
        };
    };
};

export type BookingAvailabilityProductCancellationPolicyFragment = {
    __typename: "ProductCancellationPolicy";
    isCancellable: boolean | null;
    penaltyList: {
        __typename: "ProductCancellationPolicyPenaltyList";
        nodes: Array<{ __typename: "ProductCancellationPenalty"; formattedText: string | null }>;
    };
};

export type BookingAvailabilityQuestionFragment = {
    __typename: "BookingAvailabilityQuestion";
    id: string;
    bookingId: string;
    label: string | null;
    helper: string | null;
    placeholder: string | null;
    dataType: string | null;
    dataFormat: string | null;
    defaultValue: string | null;
    isFromOptions: boolean;
    isSelectMultiple: boolean;
    isRequired: boolean;
    answerValue: string | null;
    answerFile: string | null;
    answerFormattedText: string | null;
    autoCompleteValue: string | null;
    availableOptions: Array<{ __typename: "AvailableOptions"; label: string | null; value: string | null } | null>;
    answerFileAsset: { __typename: "Asset"; url: string } | null;
};

export type BookingQuestionFragment = {
    __typename: "BookingQuestion";
    id: string;
    bookingId: string;
    type: string | null;
    label: string | null;
    helper: string | null;
    autoCompleteValue: string | null;
    placeholder: string | null;
    dataType: string | null;
    dataFormat: string | null;
    defaultValue: string | null;
    isFromOptions: boolean;
    isSelectMultiple: boolean;
    isRequired: boolean;
    answerValue: string | null;
    answerFile: string | null;
    answerFormattedText: string | null;
    availableOptions: Array<{ __typename: "AvailableOptions"; label: string | null; value: string | null } | null>;
    answerFileAsset: { __typename: "Asset"; url: string } | null;
};

export type ConsumerTripContentConsumerTripFragment = {
    __typename: "ConsumerTrip";
    consumerId: string;
    arrivalDate: string | null;
    departureDate: string | null;
    destinationCountryName: string | null;
    destinationPagePath: string | null;
    partnerExternalReference: string | null;
    consumerTripId: string;
    consumer: {
        __typename: "Consumer";
        id: string;
        givenName: string | null;
        partnerExternalReference: string | null;
    } | null;
    weatherForecast: Array<{
        __typename: "ConsumerTripWeatherForecast";
        date: string;
        type: GQLTypes.WeatherForecastTypes;
        temperature: { __typename: "Temperature"; unit: GQLTypes.TemperatureUnit; value: number };
    }> | null;
    destination: {
        __typename: "ConsumerTripDestination";
        name: string | null;
        image: {
            __typename: "ConsumerTripDestinationImage";
            heroImageAssetUrlList: Array<{ __typename: "Asset"; uri: string }>;
        } | null;
        place: { __typename: "PlaceNew"; name: string } | null;
    } | null;
};

export type ConsumerTripContentItemProductListItemFragment = {
    __typename: "ConsumerTripContentItemProductList";
    type: GQLTypes.ConsumerTripContentItemProductListType;
    layout: GQLTypes.ConsumerTripContentItemProductListLayout;
    typeParams: Array<string | null> | null;
    filter: {
        __typename: "ConsumerTripContentItemProductListFilter";
        systemTagIdList: Array<string> | null;
        placeId: string;
        systemTag: Array<{
            __typename: "SystemTagFilter";
            hasAll: Array<string> | null;
            hasUnion: Array<Array<string> | null> | null;
            hasNot: Array<string> | null;
        }> | null;
        duration: { __typename: "DurationRange"; start: string | null; end: string | null } | null;
        geoCircle: {
            __typename: "GeoCircle";
            latitude: string;
            longitude: string | number;
            accuracy: GQLTypes.GeoCircleAccuracy | null;
            radius: { __typename: "GeoCircleRadius"; value: number; unit: GQLTypes.GeoCircleRadiusUnit };
        } | null;
        startTime: { __typename: "TimeRange"; start: string | null; end: string | null } | null;
    };
};

export type ConsumerTripDestinationFragment = {
    __typename: "ConsumerTripDestination";
    name: string | null;
    image: {
        __typename: "ConsumerTripDestinationImage";
        heroImageAssetUrlList: Array<{ __typename: "Asset"; uri: string }>;
    } | null;
    place: { __typename: "PlaceNew"; name: string } | null;
};

export type ConsumerTripProductFragment = {
    __typename: "ProductListItem";
    id: string;
    name: string | null;
    slug: string | null;
    isFeatured: boolean | null;
    promotionType: string | null;
    guidePriceType: GQLTypes.ProductGuidePriceType | null;
    reviewCount: number | null;
    reviewRating: number | null;
    isFavourite: boolean | null;
    previewImage: { __typename: "ProductImage"; id: string } | null;
    holibobGuidePrice: { __typename: "Price"; pricingData: any | null; gross: number; currency: string } | null;
    metaList: {
        __typename: "ProductMetaListResponse";
        nodes: Array<{ __typename: "ProductMetaListItem"; type: string | null; value: string | null }>;
    };
    banner: { __typename: "BannerType"; text: string; backgroundColor: string } | null;
    cancellationPolicy: { __typename: "ProductCancellationPolicy"; hasFreeCancellation: boolean | null };
};

export type ConsumerTripWeatherForecastFragment = {
    __typename: "ConsumerTripWeatherForecast";
    date: string;
    type: GQLTypes.WeatherForecastTypes;
    temperature: { __typename: "Temperature"; unit: GQLTypes.TemperatureUnit; value: number };
};

export type CurationAssociationItemFragment = {
    __typename: "CurationAssociationListItem";
    id: string;
    curationId: string;
    curationName: string;
    imageVaultId: string | null;
    imageSource: string | null;
    groupName: string | null;
};

export type CurationContentItemCurationFragment = {
    __typename: "CurationContentItemCuration";
    name: string;
    id: string;
    slug: string;
    hierarchyId: string;
    heroImageAsset: { __typename: "Asset"; uri: string; id: string } | null;
};

export type CurationContentItemCurationWithProductCountFragment = {
    __typename: "CurationContentItemCuration";
    productCount: number;
    name: string;
    id: string;
    slug: string;
    hierarchyId: string;
    heroImageAsset: { __typename: "Asset"; uri: string; id: string } | null;
};

export type CurationProductFragment = {
    __typename: "ProductListItem";
    id: string;
    name: string | null;
    slug: string | null;
    isFeatured: boolean | null;
    promotionType: string | null;
    guidePriceType: GQLTypes.ProductGuidePriceType | null;
    reviewCount: number | null;
    reviewRating: number | null;
    isFavourite: boolean | null;
    previewImage: { __typename: "ProductImage"; id: string } | null;
    holibobGuidePrice: { __typename: "Price"; pricingData: any | null; gross: number; currency: string } | null;
    metaList: {
        __typename: "ProductMetaListResponse";
        nodes: Array<{ __typename: "ProductMetaListItem"; type: string | null; value: string | null }>;
    };
    banner: { __typename: "BannerType"; text: string; backgroundColor: string } | null;
    cancellationPolicy: { __typename: "ProductCancellationPolicy"; hasFreeCancellation: boolean | null };
};

type ExternalWidget_ExternalWidgetBokun_Fragment = {
    __typename: "ExternalWidgetBokun";
    bookingChannelId: string;
    widgetId: string;
};

type ExternalWidget_ExternalWidgetBookingkit_Fragment = { __typename: "ExternalWidgetBookingkit" };

type ExternalWidget_ExternalWidgetFareharbor_Fragment = {
    __typename: "ExternalWidgetFareharbor";
    itemId: number;
    flowId: number;
    slug: string;
};

type ExternalWidget_ExternalWidgetPeek_Fragment = { __typename: "ExternalWidgetPeek"; keyId: string; widgetId: string };

type ExternalWidget_ExternalWidgetRezdy_Fragment = {
    __typename: "ExternalWidgetRezdy";
    widgetId: string;
    height: number | null;
    slug: string;
};

type ExternalWidget_ExternalWidgetTicketinghub_Fragment = {
    __typename: "ExternalWidgetTicketinghub";
    widgetId: string;
};

type ExternalWidget_ExternalWidgetTravelotopos_Fragment = { __typename: "ExternalWidgetTravelotopos" };

type ExternalWidget_ExternalWidgetVentrata_Fragment = { __typename: "ExternalWidgetVentrata" };

type ExternalWidget_ExternalWidgetXola_Fragment = {
    __typename: "ExternalWidgetXola";
    sellerId: string;
    experienceId: string;
};

export type ExternalWidgetFragment =
    | ExternalWidget_ExternalWidgetBokun_Fragment
    | ExternalWidget_ExternalWidgetBookingkit_Fragment
    | ExternalWidget_ExternalWidgetFareharbor_Fragment
    | ExternalWidget_ExternalWidgetPeek_Fragment
    | ExternalWidget_ExternalWidgetRezdy_Fragment
    | ExternalWidget_ExternalWidgetTicketinghub_Fragment
    | ExternalWidget_ExternalWidgetTravelotopos_Fragment
    | ExternalWidget_ExternalWidgetVentrata_Fragment
    | ExternalWidget_ExternalWidgetXola_Fragment;

export type ProductContentEntryFragment = {
    __typename: "ProductContent";
    name: string | null;
    description: string | null;
    descriptionJson: any | null;
    type: GQLTypes.ProductContentType;
    ordinalPosition: number | null;
    synchronisationId: string | null;
};

export type ProductPriceFragment = { __typename: "Price"; pricingData: any | null; gross: number; currency: string };

export type ProductItemFragment = {
    __typename: "ProductListItem";
    id: string;
    code: string | null;
    slug: string | null;
    name: string | null;
    isFeatured: boolean | null;
    isFavourite: boolean | null;
    promotionType: string | null;
    guidePriceType: GQLTypes.ProductGuidePriceType | null;
    reviewRating: number | null;
    reviewCount: number | null;
    type: GQLTypes.ProductTypeType;
    availabilityCount: number | null;
    holibobGuidePrice: { __typename: "Price"; pricingData: any | null; gross: number; currency: string } | null;
    previewImage: { __typename: "ProductImage"; id: string } | null;
    metaList: {
        __typename: "ProductMetaListResponse";
        nodes: Array<{ __typename: "ProductMetaListItem"; type: string | null; value: string | null }>;
    };
    cancellationPolicy: { __typename: "ProductCancellationPolicy"; hasFreeCancellation: boolean | null };
    banner: { __typename: "BannerType"; text: string; backgroundColor: string } | null;
};

export type ProductItineraryItemFragment = {
    __typename: "ProductItineraryItem";
    id: string;
    name: string | null;
    duration: string | null;
    description: { __typename: "HBML"; enhanced: any } | null;
    extraInformation: {
        __typename: "ProductItineraryItemExtraInformation";
        day: number | null;
        isAdmissionIncluded: boolean | null;
        passByWithoutStopping: boolean | null;
    };
};

export type ProductProviderFragment = { __typename: "Provider"; name: string };

export type ProductSourceInstanceFragment = {
    __typename: "SynchronisationSource";
    name: string;
    sourceName: string | null;
};

export type ProductStartPlaceFragment = {
    __typename: "PlaceNew";
    timeZone: string | null;
    googlePlaceId: string;
    formattedAddress: string;
    mapImageUrl: string;
    geoCoordinate: { __typename: "PlaceNewGeoCoordinate"; latitude: number; longitude: number };
};

export type BookingAddAvailabilityMutationVariables = GQLTypes.Exact<{
    input: GQLTypes.BookingAddAvailabilityInputType;
    saleCurrency?: GQLTypes.InputMaybe<GQLTypes.Scalars["String"]["input"]>;
}>;

export type BookingAddAvailabilityMutation = {
    __typename: "Mutations";
    bookingAddAvailability: { __typename: "Booking"; id: string } | null;
};

export type BookingAmendAvailabilityIntentMutationVariables = GQLTypes.Exact<{
    availabilityId: GQLTypes.Scalars["String"]["input"];
    newAvailabilityId: GQLTypes.Scalars["String"]["input"];
}>;

export type BookingAmendAvailabilityIntentMutation = {
    __typename: "Mutations";
    bookingAmendAvailabilityIntent: { __typename: "Booking"; id: string } | null;
};

export type BookingCancelMutationVariables = GQLTypes.Exact<{
    input: GQLTypes.BookingCancelInput;
}>;

export type BookingCancelMutation = { __typename: "Mutations"; bookingCancel: { __typename: "Booking"; id: string } };

export type BookingCommitMutationVariables = GQLTypes.Exact<{
    bookingSelector: GQLTypes.BookingSelector;
}>;

export type BookingCommitMutation = {
    __typename: "Mutations";
    booking: { __typename: "Booking"; id: string; state: GQLTypes.BookingStateType } | null;
};

export type BookingDeleteAvailabilityMutationVariables = GQLTypes.Exact<{
    input: GQLTypes.BookingDeleteAvailabilityInputType;
}>;

export type BookingDeleteAvailabilityMutation = {
    __typename: "Mutations";
    booking: { __typename: "Booking"; id: string };
};

export type BookingEditMutationVariables = GQLTypes.Exact<{
    input?: GQLTypes.InputMaybe<GQLTypes.BookingEditInput>;
}>;

export type BookingEditMutation = {
    __typename: "Mutations";
    booking: {
        __typename: "Booking";
        id: string;
        name: string | null;
        state: GQLTypes.BookingStateType;
        partnerId: string | null;
        code: string;
        isSandboxed: boolean;
    } | null;
};

export type BookingQuestionUploadMutationVariables = GQLTypes.Exact<{
    bookingId: GQLTypes.Scalars["ID"]["input"];
    bookingQuestionId: GQLTypes.Scalars["ID"]["input"];
    contentType: GQLTypes.Scalars["String"]["input"];
}>;

export type BookingQuestionUploadMutation = {
    __typename: "Mutations";
    bookingQuestionUpload: {
        __typename: "BookingQuestionUpload";
        uploadUrl: string;
        asset: { __typename: "Asset"; id: string; uri: string; url: string };
    } | null;
};

export type BookingReplaceAvailabilityMutationVariables = GQLTypes.Exact<{
    availabilityId: GQLTypes.Scalars["String"]["input"];
    newAvailabilityId: GQLTypes.Scalars["String"]["input"];
}>;

export type BookingReplaceAvailabilityMutation = {
    __typename: "Mutations";
    bookingReplaceAvailability: { __typename: "Booking"; id: string } | null;
};

export type BookingRevertAmendAvailabilityIntentMutationVariables = GQLTypes.Exact<{
    availabilityId: GQLTypes.Scalars["String"]["input"];
}>;

export type BookingRevertAmendAvailabilityIntentMutation = {
    __typename: "Mutations";
    bookingRevertAmendAvailabilityIntent: { __typename: "GenericSignalResponseType"; sent: boolean | null };
};

export type ConsumerTripAssociateSystemTagListMutationVariables = GQLTypes.Exact<{
    consumerTripId: GQLTypes.Scalars["String"]["input"];
    systemTagIdList: Array<GQLTypes.Scalars["String"]["input"]> | GQLTypes.Scalars["String"]["input"];
}>;

export type ConsumerTripAssociateSystemTagListMutation = {
    __typename: "Mutations";
    consumerTripAssociateSystemTagList: boolean;
};

export type ConsumerTripEventCreateMutationVariables = GQLTypes.Exact<{
    consumerTripId: GQLTypes.Scalars["String"]["input"];
    type: GQLTypes.Scalars["String"]["input"];
    meta: GQLTypes.Scalars["JSON"]["input"];
}>;

export type ConsumerTripEventCreateMutation = { __typename: "Mutations"; consumerTripEventCreate: boolean };

export type SignalBookingAvailabilityCancellationRevertMutationVariables = GQLTypes.Exact<{
    input: GQLTypes.GenericBookingAvailabilitySignalInput;
}>;

export type SignalBookingAvailabilityCancellationRevertMutation = {
    __typename: "Mutations";
    signalBookingAvailabilityCancellationRevert: { __typename: "GenericSignalResponseType"; sent: boolean | null };
};

export type SignalBookingAvailabilityListCancellationIntentMutationVariables = GQLTypes.Exact<{
    input: GQLTypes.SignalBookingAvailabilityListCancellationIntentInput;
}>;

export type SignalBookingAvailabilityListCancellationIntentMutation = {
    __typename: "Mutations";
    signalBookingAvailabilityListCancellationIntent: { __typename: "GenericSignalResponseType"; sent: boolean | null };
};

export type SignalBookingAvailabilityListForceCancellationIntentMutationVariables = GQLTypes.Exact<{
    input: GQLTypes.SignalBookingAvailabilityListCancellationIntentInput;
}>;

export type SignalBookingAvailabilityListForceCancellationIntentMutation = {
    __typename: "Mutations";
    signalBookingAvailabilityListForceCancellationIntent: {
        __typename: "GenericSignalResponseType";
        sent: boolean | null;
    };
};

export type AvailabilityDatePaxListQueryVariables = GQLTypes.Exact<{
    productId: GQLTypes.Scalars["String"]["input"];
    optionList?: GQLTypes.InputMaybe<
        | Array<GQLTypes.InputMaybe<GQLTypes.AvailabilityListOptionListItemInput>>
        | GQLTypes.InputMaybe<GQLTypes.AvailabilityListOptionListItemInput>
    >;
    sessionId?: GQLTypes.InputMaybe<GQLTypes.Scalars["ID"]["input"]>;
}>;

export type AvailabilityDatePaxListQuery = {
    __typename: "Queries";
    availabilityList: {
        __typename: "AvailabilityListResponse";
        nodes: Array<{ __typename: "AvailabilityListItem"; id: string }>;
    } | null;
};

export type AvailabilityHotelListQueryVariables = GQLTypes.Exact<{
    productId: GQLTypes.Scalars["String"]["input"];
    filter?: GQLTypes.InputMaybe<GQLTypes.AvailabilityListFilter>;
}>;

export type AvailabilityHotelListQuery = {
    __typename: "Queries";
    availabilityList: {
        __typename: "AvailabilityListResponse";
        total: number;
        nodes: Array<{
            __typename: "AvailabilityListItem";
            id: string;
            date: string | null;
            endDate: string | null;
            soldOut: boolean;
        }>;
    } | null;
};

export type BookingQueryVariables = GQLTypes.Exact<{
    id: GQLTypes.Scalars["String"]["input"];
    input?: GQLTypes.InputMaybe<GQLTypes.BookingInput>;
}>;

export type BookingQuery = {
    __typename: "Queries";
    booking: {
        __typename: "Booking";
        id: string;
        code: string;
        leadPassengerName: string | null;
        partnerName: string | null;
        state: GQLTypes.BookingStateType;
        paymentState: GQLTypes.BookingPaymentStateType | null;
        isSandboxed: boolean;
        isWorkflowProcessing: boolean;
        workflowInitialisedAt: string | null;
        isComplete: boolean;
        reference: string | null;
        saleCurrency: string | null;
        canCommit: boolean | null;
        isQuestionsComplete: boolean | null;
        voucherUrl: string | null;
        canCancel: boolean | null;
        createdAt: string | null;
        voucherCreatedAt: string | null;
        bookingUrl: string;
        emailSummarySentAt: string | null;
        termAndCondition: string | null;
        useLifecycleManager: boolean;
        isPendingCommit: boolean | null;
        consumerTrip: { __typename: "ConsumerTripBasic"; id: string; placeId: string | null } | null;
        cancellationEffectiveRefundAmount: {
            __typename: "BasicPrice";
            amount: number;
            currency: string;
            formattedText: string;
        } | null;
        contact: { __typename: "BookingContact"; email: string; phone: string; name: string } | null;
        totalPrice: {
            __typename: "Price";
            gross: number;
            grossFormattedText: string;
            netFormattedText: string;
            commissionFormattedText: string;
            currency: string;
        };
        outstandingConsumerAmount: {
            __typename: "BasicPrice";
            amount: number;
            currency: string;
            formattedText: string;
        };
        questionList: {
            __typename: "BookingQuestionList";
            nodes: Array<{
                __typename: "BookingQuestion";
                id: string;
                bookingId: string;
                type: string | null;
                label: string | null;
                helper: string | null;
                autoCompleteValue: string | null;
                placeholder: string | null;
                dataType: string | null;
                dataFormat: string | null;
                defaultValue: string | null;
                isFromOptions: boolean;
                isSelectMultiple: boolean;
                isRequired: boolean;
                answerValue: string | null;
                answerFile: string | null;
                answerFormattedText: string | null;
                availableOptions: Array<{
                    __typename: "AvailableOptions";
                    label: string | null;
                    value: string | null;
                } | null>;
                answerFileAsset: { __typename: "Asset"; url: string } | null;
            }>;
        };
        availabilityList: {
            __typename: "BookingAvailabilityList";
            nodes: Array<{
                __typename: "BookingAvailability";
                id: string;
                date: string | null;
                startAt: string | null;
                createdAt: string;
                type: GQLTypes.BookingAvailabilityType;
                timeZone: string;
                productName: string;
                productId: string;
                refreshStatus: GQLTypes.BookingAvailabilityRefreshStatus | null;
                canCancel: boolean;
                canRevertCancellation: boolean;
                canForceCancel: boolean;
                canEdit: boolean;
                canRevertEdit: boolean;
                canAmend: boolean;
                code: string;
                saleCurrency: string | null;
                bookingId: string;
                originBookingAvailabilityId: string | null;
                voucherUrl: string | null;
                isActive: boolean;
                isConfirmed: boolean;
                isQuestionsComplete: boolean;
                state: GQLTypes.BookingAvailabilityProcessState;
                parent: {
                    __typename: "BookingAvailabilitySimple";
                    refreshStatus: GQLTypes.BookingAvailabilityRefreshStatus | null;
                    totalPrice: { __typename: "Price"; grossFormattedText: string };
                } | null;
                totalPrice: {
                    __typename: "Price";
                    grossFormattedText: string;
                    netFormattedText: string;
                    commissionFormattedText: string;
                    gross: number;
                    currency: string;
                };
                product: {
                    __typename: "Product";
                    id: string;
                    name: string | null;
                    availabilityType: GQLTypes.AvailabilityType;
                    previewImage: { __typename: "ProductImage"; id: string } | null;
                    cancellationPolicy: {
                        __typename: "ProductCancellationPolicy";
                        isCancellable: boolean | null;
                        penaltyList: {
                            __typename: "ProductCancellationPolicyPenaltyList";
                            nodes: Array<{ __typename: "ProductCancellationPenalty"; formattedText: string | null }>;
                        };
                    };
                };
                optionList: {
                    __typename: "BookingAvailabilityOptionList";
                    nodes: Array<{
                        __typename: "BookingAvailabilityOption";
                        id: string;
                        isAnswered: boolean;
                        label: string | null;
                        level: number | null;
                        helper: string | null;
                        placeholder: string | null;
                        dataType: string | null;
                        answerValue: string | null;
                        answerFormattedText: string | null;
                        type: string | null;
                        availableOptions: Array<{
                            __typename: "AvailableOptions";
                            label: string | null;
                            value: string | null;
                        } | null>;
                    }>;
                };
                questionList: {
                    __typename: "BookingAvailabilityQuestionList";
                    nodes: Array<{
                        __typename: "BookingAvailabilityQuestion";
                        id: string;
                        bookingId: string;
                        label: string | null;
                        helper: string | null;
                        placeholder: string | null;
                        dataType: string | null;
                        dataFormat: string | null;
                        defaultValue: string | null;
                        isFromOptions: boolean;
                        isSelectMultiple: boolean;
                        isRequired: boolean;
                        answerValue: string | null;
                        answerFile: string | null;
                        answerFormattedText: string | null;
                        autoCompleteValue: string | null;
                        availableOptions: Array<{
                            __typename: "AvailableOptions";
                            label: string | null;
                            value: string | null;
                        } | null>;
                        answerFileAsset: { __typename: "Asset"; url: string } | null;
                    }>;
                };
                personList: {
                    __typename: "BookingAvailabilityPersonList";
                    nodes: Array<{
                        __typename: "BookingAvailabilityPerson";
                        id: string;
                        pricingCategoryLabel: string | null;
                        isQuestionsComplete: boolean;
                        totalPrice: { __typename: "Price"; grossFormattedText: string; netFormattedText: string };
                        questionList: {
                            __typename: "BookingAvailabilityPersonQuestionList";
                            nodes: Array<{
                                __typename: "BookingAvailabilityPersonQuestion";
                                id: string;
                                bookingId: string;
                                label: string | null;
                                helper: string | null;
                                placeholder: string | null;
                                dataType: string | null;
                                dataFormat: string | null;
                                defaultValue: string | null;
                                isFromOptions: boolean;
                                isSelectMultiple: boolean;
                                isRequired: boolean;
                                answerValue: string | null;
                                answerFile: string | null;
                                answerFormattedText: string | null;
                                autoCompleteValue: string | null;
                                availableOptions: Array<{
                                    __typename: "AvailableOptions";
                                    label: string | null;
                                    value: string | null;
                                } | null>;
                                answerFileAsset: { __typename: "Asset"; url: string } | null;
                            }>;
                        };
                    }>;
                };
                cancellationState: {
                    __typename: "BookingAvailabilityCancellationState";
                    effectiveRefundState: GQLTypes.BookingAvailabilityCancellationEffectiveRefundState;
                    effectiveRefundAmount: { __typename: "BasicPrice"; amount: number; formattedText: string };
                    effectivePenalty: {
                        __typename: "CancellationPenalty";
                        type: GQLTypes.CancellationPenaltyType;
                        relativeTo: GQLTypes.CancellationPenaltyRelativeToType;
                        duration: string | null;
                        refundPercentage: number | null;
                        amount: number | null;
                        amountType: string | null;
                        amountCurrency: string | null;
                        formattedText: string | null;
                    };
                } | null;
                cancellationPolicy: {
                    __typename: "BookingAvailabilityCancellationPolicy";
                    isCancellable: boolean;
                    penaltyList: {
                        __typename: "BookingAvailabilityCancellationPolicyPenaltyList";
                        nodes: Array<{
                            __typename: "ProductCancellationPenalty";
                            formattedText: string | null;
                            ordinalPosition: number | null;
                        }>;
                    };
                };
                originBookingAvailability: {
                    __typename: "BookingAvailabilitySimple";
                    id: string;
                    startAt: string | null;
                    endAt: string | null;
                    createdAt: string;
                    timeZone: string;
                    type: GQLTypes.BookingAvailabilityType;
                    productName: string;
                    state: GQLTypes.BookingAvailabilityProcessState;
                    totalPrice: { __typename: "Price"; grossFormattedText: string };
                    personList: {
                        __typename: "BookingAvailabilityPersonList";
                        nodes: Array<{
                            __typename: "BookingAvailabilityPerson";
                            id: string;
                            pricingCategoryLabel: string | null;
                            isQuestionsComplete: boolean;
                            totalPrice: { __typename: "Price"; grossFormattedText: string; netFormattedText: string };
                            questionList: {
                                __typename: "BookingAvailabilityPersonQuestionList";
                                nodes: Array<{
                                    __typename: "BookingAvailabilityPersonQuestion";
                                    id: string;
                                    bookingId: string;
                                    label: string | null;
                                    helper: string | null;
                                    placeholder: string | null;
                                    dataType: string | null;
                                    dataFormat: string | null;
                                    defaultValue: string | null;
                                    isFromOptions: boolean;
                                    isSelectMultiple: boolean;
                                    isRequired: boolean;
                                    answerValue: string | null;
                                    answerFile: string | null;
                                    answerFormattedText: string | null;
                                    autoCompleteValue: string | null;
                                    availableOptions: Array<{
                                        __typename: "AvailableOptions";
                                        label: string | null;
                                        value: string | null;
                                    } | null>;
                                    answerFileAsset: { __typename: "Asset"; url: string } | null;
                                }>;
                            };
                        }>;
                    };
                    optionList: {
                        __typename: "BookingAvailabilityOptionList";
                        recordCount: number;
                        nodes: Array<{
                            __typename: "BookingAvailabilityOption";
                            id: string;
                            isAnswered: boolean;
                            label: string | null;
                            level: number | null;
                            helper: string | null;
                            placeholder: string | null;
                            dataType: string | null;
                            answerValue: string | null;
                            answerFormattedText: string | null;
                            type: string | null;
                            availableOptions: Array<{
                                __typename: "AvailableOptions";
                                label: string | null;
                                value: string | null;
                            } | null>;
                        }>;
                    };
                } | null;
            }>;
        };
    } | null;
};

export type BookingAvailabilityDomainQueryVariables = GQLTypes.Exact<{
    id: GQLTypes.Scalars["String"]["input"];
}>;

export type BookingAvailabilityDomainQuery = {
    __typename: "Queries";
    bookingAvailability: {
        __typename: "BookingAvailability";
        id: string;
        date: string | null;
        startAt: string | null;
        createdAt: string;
        type: GQLTypes.BookingAvailabilityType;
        timeZone: string;
        productName: string;
        productId: string;
        refreshStatus: GQLTypes.BookingAvailabilityRefreshStatus | null;
        canCancel: boolean;
        canRevertCancellation: boolean;
        canForceCancel: boolean;
        canEdit: boolean;
        canRevertEdit: boolean;
        canAmend: boolean;
        code: string;
        saleCurrency: string | null;
        bookingId: string;
        useLifecycleManager: boolean;
        originBookingAvailabilityId: string | null;
        voucherUrl: string | null;
        isActive: boolean;
        isConfirmed: boolean;
        isQuestionsComplete: boolean;
        state: GQLTypes.BookingAvailabilityProcessState;
        parent: {
            __typename: "BookingAvailabilitySimple";
            refreshStatus: GQLTypes.BookingAvailabilityRefreshStatus | null;
            totalPrice: { __typename: "Price"; grossFormattedText: string };
        } | null;
        totalPrice: {
            __typename: "Price";
            grossFormattedText: string;
            netFormattedText: string;
            commissionFormattedText: string;
            gross: number;
            currency: string;
        };
        product: {
            __typename: "Product";
            id: string;
            name: string | null;
            availabilityType: GQLTypes.AvailabilityType;
            previewImage: { __typename: "ProductImage"; id: string } | null;
            cancellationPolicy: {
                __typename: "ProductCancellationPolicy";
                isCancellable: boolean | null;
                penaltyList: {
                    __typename: "ProductCancellationPolicyPenaltyList";
                    nodes: Array<{ __typename: "ProductCancellationPenalty"; formattedText: string | null }>;
                };
            };
        };
        optionList: {
            __typename: "BookingAvailabilityOptionList";
            nodes: Array<{
                __typename: "BookingAvailabilityOption";
                id: string;
                isAnswered: boolean;
                label: string | null;
                level: number | null;
                helper: string | null;
                placeholder: string | null;
                dataType: string | null;
                answerValue: string | null;
                answerFormattedText: string | null;
                type: string | null;
                availableOptions: Array<{
                    __typename: "AvailableOptions";
                    label: string | null;
                    value: string | null;
                } | null>;
            }>;
        };
        bookingQuestionList: {
            __typename: "BookingQuestionList";
            nodes: Array<{
                __typename: "BookingQuestion";
                id: string;
                bookingId: string;
                type: string | null;
                label: string | null;
                helper: string | null;
                autoCompleteValue: string | null;
                placeholder: string | null;
                dataType: string | null;
                dataFormat: string | null;
                defaultValue: string | null;
                isFromOptions: boolean;
                isSelectMultiple: boolean;
                isRequired: boolean;
                answerValue: string | null;
                answerFile: string | null;
                answerFormattedText: string | null;
                availableOptions: Array<{
                    __typename: "AvailableOptions";
                    label: string | null;
                    value: string | null;
                } | null>;
                answerFileAsset: { __typename: "Asset"; url: string } | null;
            }>;
        };
        questionList: {
            __typename: "BookingAvailabilityQuestionList";
            nodes: Array<{
                __typename: "BookingAvailabilityQuestion";
                id: string;
                bookingId: string;
                label: string | null;
                helper: string | null;
                placeholder: string | null;
                dataType: string | null;
                dataFormat: string | null;
                defaultValue: string | null;
                isFromOptions: boolean;
                isSelectMultiple: boolean;
                isRequired: boolean;
                answerValue: string | null;
                answerFile: string | null;
                answerFormattedText: string | null;
                autoCompleteValue: string | null;
                availableOptions: Array<{
                    __typename: "AvailableOptions";
                    label: string | null;
                    value: string | null;
                } | null>;
                answerFileAsset: { __typename: "Asset"; url: string } | null;
            }>;
        };
        personList: {
            __typename: "BookingAvailabilityPersonList";
            nodes: Array<{
                __typename: "BookingAvailabilityPerson";
                id: string;
                pricingCategoryLabel: string | null;
                isQuestionsComplete: boolean;
                totalPrice: { __typename: "Price"; grossFormattedText: string; netFormattedText: string };
                questionList: {
                    __typename: "BookingAvailabilityPersonQuestionList";
                    nodes: Array<{
                        __typename: "BookingAvailabilityPersonQuestion";
                        id: string;
                        bookingId: string;
                        label: string | null;
                        helper: string | null;
                        placeholder: string | null;
                        dataType: string | null;
                        dataFormat: string | null;
                        defaultValue: string | null;
                        isFromOptions: boolean;
                        isSelectMultiple: boolean;
                        isRequired: boolean;
                        answerValue: string | null;
                        answerFile: string | null;
                        answerFormattedText: string | null;
                        autoCompleteValue: string | null;
                        availableOptions: Array<{
                            __typename: "AvailableOptions";
                            label: string | null;
                            value: string | null;
                        } | null>;
                        answerFileAsset: { __typename: "Asset"; url: string } | null;
                    }>;
                };
            }>;
        };
        cancellationState: {
            __typename: "BookingAvailabilityCancellationState";
            effectiveRefundState: GQLTypes.BookingAvailabilityCancellationEffectiveRefundState;
            effectiveRefundAmount: { __typename: "BasicPrice"; amount: number; formattedText: string };
            effectivePenalty: {
                __typename: "CancellationPenalty";
                type: GQLTypes.CancellationPenaltyType;
                relativeTo: GQLTypes.CancellationPenaltyRelativeToType;
                duration: string | null;
                refundPercentage: number | null;
                amount: number | null;
                amountType: string | null;
                amountCurrency: string | null;
                formattedText: string | null;
            };
        } | null;
        cancellationPolicy: {
            __typename: "BookingAvailabilityCancellationPolicy";
            isCancellable: boolean;
            penaltyList: {
                __typename: "BookingAvailabilityCancellationPolicyPenaltyList";
                nodes: Array<{
                    __typename: "ProductCancellationPenalty";
                    formattedText: string | null;
                    ordinalPosition: number | null;
                }>;
            };
        };
        originBookingAvailability: {
            __typename: "BookingAvailabilitySimple";
            id: string;
            startAt: string | null;
            endAt: string | null;
            createdAt: string;
            timeZone: string;
            type: GQLTypes.BookingAvailabilityType;
            productName: string;
            state: GQLTypes.BookingAvailabilityProcessState;
            totalPrice: { __typename: "Price"; grossFormattedText: string };
            personList: {
                __typename: "BookingAvailabilityPersonList";
                nodes: Array<{
                    __typename: "BookingAvailabilityPerson";
                    id: string;
                    pricingCategoryLabel: string | null;
                    isQuestionsComplete: boolean;
                    totalPrice: { __typename: "Price"; grossFormattedText: string; netFormattedText: string };
                    questionList: {
                        __typename: "BookingAvailabilityPersonQuestionList";
                        nodes: Array<{
                            __typename: "BookingAvailabilityPersonQuestion";
                            id: string;
                            bookingId: string;
                            label: string | null;
                            helper: string | null;
                            placeholder: string | null;
                            dataType: string | null;
                            dataFormat: string | null;
                            defaultValue: string | null;
                            isFromOptions: boolean;
                            isSelectMultiple: boolean;
                            isRequired: boolean;
                            answerValue: string | null;
                            answerFile: string | null;
                            answerFormattedText: string | null;
                            autoCompleteValue: string | null;
                            availableOptions: Array<{
                                __typename: "AvailableOptions";
                                label: string | null;
                                value: string | null;
                            } | null>;
                            answerFileAsset: { __typename: "Asset"; url: string } | null;
                        }>;
                    };
                }>;
            };
            optionList: {
                __typename: "BookingAvailabilityOptionList";
                recordCount: number;
                nodes: Array<{
                    __typename: "BookingAvailabilityOption";
                    id: string;
                    isAnswered: boolean;
                    label: string | null;
                    level: number | null;
                    helper: string | null;
                    placeholder: string | null;
                    dataType: string | null;
                    answerValue: string | null;
                    answerFormattedText: string | null;
                    type: string | null;
                    availableOptions: Array<{
                        __typename: "AvailableOptions";
                        label: string | null;
                        value: string | null;
                    } | null>;
                }>;
            };
        } | null;
        bookingInfoSummary: {
            __typename: "BookingInfoSummary";
            state: GQLTypes.BookingStateType;
            code: string;
            leadPassengerName: string | null;
            partnerExternalReference: string | null;
        };
    } | null;
};

export type ConsumerTripQueryVariables = GQLTypes.Exact<{
    consumerTripId: GQLTypes.Scalars["ID"]["input"];
}>;

export type ConsumerTripQuery = {
    __typename: "Queries";
    consumerTrip: {
        __typename: "ConsumerTrip";
        consumerId: string;
        arrivalDate: string | null;
        departureDate: string | null;
        destinationCountryName: string | null;
        destinationPagePath: string | null;
        partnerExternalReference: string | null;
        consumerTripId: string;
        consumer: {
            __typename: "Consumer";
            id: string;
            givenName: string | null;
            partnerExternalReference: string | null;
        } | null;
        weatherForecast: Array<{
            __typename: "ConsumerTripWeatherForecast";
            date: string;
            type: GQLTypes.WeatherForecastTypes;
            temperature: { __typename: "Temperature"; unit: GQLTypes.TemperatureUnit; value: number };
        }> | null;
        destination: {
            __typename: "ConsumerTripDestination";
            name: string | null;
            image: {
                __typename: "ConsumerTripDestinationImage";
                heroImageAssetUrlList: Array<{ __typename: "Asset"; uri: string }>;
            } | null;
            place: { __typename: "PlaceNew"; name: string } | null;
        } | null;
    } | null;
};

export type ConsumerTripContentQueryVariables = GQLTypes.Exact<{
    consumerTripId: GQLTypes.Scalars["String"]["input"];
    placeId: GQLTypes.Scalars["String"]["input"];
}>;

export type ConsumerTripContentQuery = {
    __typename: "Queries";
    consumerTripContent: {
        __typename: "ConsumerTripContent";
        systemTagIdList: Array<string>;
        itemList: Array<{
            __typename: "ConsumerTripContentItemProductList";
            type: GQLTypes.ConsumerTripContentItemProductListType;
            layout: GQLTypes.ConsumerTripContentItemProductListLayout;
            typeParams: Array<string | null> | null;
            filter: {
                __typename: "ConsumerTripContentItemProductListFilter";
                systemTagIdList: Array<string> | null;
                placeId: string;
                systemTag: Array<{
                    __typename: "SystemTagFilter";
                    hasAll: Array<string> | null;
                    hasUnion: Array<Array<string> | null> | null;
                    hasNot: Array<string> | null;
                }> | null;
                duration: { __typename: "DurationRange"; start: string | null; end: string | null } | null;
                geoCircle: {
                    __typename: "GeoCircle";
                    latitude: string;
                    longitude: string | number;
                    accuracy: GQLTypes.GeoCircleAccuracy | null;
                    radius: { __typename: "GeoCircleRadius"; value: number; unit: GQLTypes.GeoCircleRadiusUnit };
                } | null;
                startTime: { __typename: "TimeRange"; start: string | null; end: string | null } | null;
            };
        }>;
    } | null;
};

export type ConsumerTripProductListQueryVariables = GQLTypes.Exact<{
    filter?: GQLTypes.InputMaybe<GQLTypes.ProductListFilter>;
    pageSize?: GQLTypes.InputMaybe<GQLTypes.Scalars["Int"]["input"]>;
}>;

export type ConsumerTripProductListQuery = {
    __typename: "Queries";
    productList: {
        __typename: "ProductList";
        nodes: Array<{
            __typename: "ProductListItem";
            id: string;
            name: string | null;
            slug: string | null;
            isFeatured: boolean | null;
            promotionType: string | null;
            guidePriceType: GQLTypes.ProductGuidePriceType | null;
            reviewCount: number | null;
            reviewRating: number | null;
            isFavourite: boolean | null;
            previewImage: { __typename: "ProductImage"; id: string } | null;
            holibobGuidePrice: { __typename: "Price"; pricingData: any | null; gross: number; currency: string } | null;
            metaList: {
                __typename: "ProductMetaListResponse";
                nodes: Array<{ __typename: "ProductMetaListItem"; type: string | null; value: string | null }>;
            };
            banner: { __typename: "BannerType"; text: string; backgroundColor: string } | null;
            cancellationPolicy: { __typename: "ProductCancellationPolicy"; hasFreeCancellation: boolean | null };
        }>;
    } | null;
};

export type ConsumerTripRecentlyViewedProductListQueryVariables = GQLTypes.Exact<{
    consumerTripId: GQLTypes.Scalars["String"]["input"];
}>;

export type ConsumerTripRecentlyViewedProductListQuery = {
    __typename: "Queries";
    consumerTripRecentlyViewedProductList: {
        __typename: "ProductList";
        nodes: Array<{
            __typename: "ProductListItem";
            id: string;
            name: string | null;
            slug: string | null;
            isFeatured: boolean | null;
            promotionType: string | null;
            guidePriceType: GQLTypes.ProductGuidePriceType | null;
            reviewCount: number | null;
            reviewRating: number | null;
            isFavourite: boolean | null;
            previewImage: { __typename: "ProductImage"; id: string } | null;
            holibobGuidePrice: { __typename: "Price"; pricingData: any | null; gross: number; currency: string } | null;
            metaList: {
                __typename: "ProductMetaListResponse";
                nodes: Array<{ __typename: "ProductMetaListItem"; type: string | null; value: string | null }>;
            };
            banner: { __typename: "BannerType"; text: string; backgroundColor: string } | null;
            cancellationPolicy: { __typename: "ProductCancellationPolicy"; hasFreeCancellation: boolean | null };
        }>;
    } | null;
};

export type ConsumerTripRecommendedProductListQueryVariables = GQLTypes.Exact<{
    consumerTripId: GQLTypes.Scalars["ID"]["input"];
    placeId: GQLTypes.Scalars["ID"]["input"];
}>;

export type ConsumerTripRecommendedProductListQuery = {
    __typename: "Queries";
    consumerTripRecommendedProductList: {
        __typename: "ProductList";
        nodes: Array<{
            __typename: "ProductListItem";
            id: string;
            name: string | null;
            slug: string | null;
            isFeatured: boolean | null;
            promotionType: string | null;
            guidePriceType: GQLTypes.ProductGuidePriceType | null;
            reviewCount: number | null;
            reviewRating: number | null;
            isFavourite: boolean | null;
            previewImage: { __typename: "ProductImage"; id: string } | null;
            holibobGuidePrice: { __typename: "Price"; pricingData: any | null; gross: number; currency: string } | null;
            metaList: {
                __typename: "ProductMetaListResponse";
                nodes: Array<{ __typename: "ProductMetaListItem"; type: string | null; value: string | null }>;
            };
            banner: { __typename: "BannerType"; text: string; backgroundColor: string } | null;
            cancellationPolicy: { __typename: "ProductCancellationPolicy"; hasFreeCancellation: boolean | null };
        }>;
    } | null;
};

export type CurationAndCurationProductsQueryVariables = GQLTypes.Exact<{
    curationId: GQLTypes.Scalars["String"]["input"];
    pageSize?: GQLTypes.InputMaybe<GQLTypes.Scalars["Int"]["input"]>;
    includeProducts?: GQLTypes.InputMaybe<GQLTypes.Scalars["Boolean"]["input"]>;
}>;

export type CurationAndCurationProductsQuery = {
    __typename: "Queries";
    productList?: {
        __typename: "ProductList";
        nodes: Array<{
            __typename: "ProductListItem";
            id: string;
            name: string | null;
            slug: string | null;
            isFeatured: boolean | null;
            promotionType: string | null;
            guidePriceType: GQLTypes.ProductGuidePriceType | null;
            reviewCount: number | null;
            reviewRating: number | null;
            isFavourite: boolean | null;
            previewImage: { __typename: "ProductImage"; id: string } | null;
            holibobGuidePrice: { __typename: "Price"; pricingData: any | null; gross: number; currency: string } | null;
            metaList: {
                __typename: "ProductMetaListResponse";
                nodes: Array<{ __typename: "ProductMetaListItem"; type: string | null; value: string | null }>;
            };
            banner: { __typename: "BannerType"; text: string; backgroundColor: string } | null;
            cancellationPolicy: { __typename: "ProductCancellationPolicy"; hasFreeCancellation: boolean | null };
        }>;
    } | null;
    hierarchyCurationList: {
        __typename: "HierarchyCurationListType";
        nodes: Array<{
            __typename: "CurationContentItemCuration";
            name: string;
            id: string;
            slug: string;
            hierarchyId: string;
            heroImageAsset: { __typename: "Asset"; uri: string; id: string } | null;
        }>;
    } | null;
};

export type CurationContentLegacyQueryVariables = GQLTypes.Exact<{
    id: GQLTypes.Scalars["String"]["input"];
}>;

export type CurationContentLegacyQuery = {
    __typename: "Queries";
    curationContentLegacy: {
        __typename: "CurationContent";
        curation: {
            __typename: "CurationContentItemCuration";
            id: string;
            name: string;
            slug: string;
            productCount: number;
            heroImageAsset: { __typename: "Asset"; id: string } | null;
        };
        children: Array<{
            __typename: "CurationContentItemCuration";
            name: string;
            id: string;
            slug: string;
            hierarchyId: string;
            heroImageAsset: { __typename: "Asset"; uri: string; id: string } | null;
        }>;
    } | null;
};

export type CurationContentLegacyWithProductCountQueryVariables = GQLTypes.Exact<{
    id: GQLTypes.Scalars["String"]["input"];
}>;

export type CurationContentLegacyWithProductCountQuery = {
    __typename: "Queries";
    curationContentLegacy: {
        __typename: "CurationContent";
        children: Array<{
            __typename: "CurationContentItemCuration";
            productCount: number;
            name: string;
            id: string;
            slug: string;
            hierarchyId: string;
            heroImageAsset: { __typename: "Asset"; uri: string; id: string } | null;
        }>;
    } | null;
};

export type CurationListQueryVariables = GQLTypes.Exact<{
    filter?: GQLTypes.InputMaybe<GQLTypes.HierarchyCurationListFilterType>;
    pageSize?: GQLTypes.InputMaybe<GQLTypes.Scalars["Int"]["input"]>;
}>;

export type CurationListQuery = {
    __typename: "Queries";
    hierarchyCurationList: {
        __typename: "HierarchyCurationListType";
        nodes: Array<{
            __typename: "CurationContentItemCuration";
            name: string;
            id: string;
            slug: string;
            hierarchyId: string;
            heroImageAsset: { __typename: "Asset"; uri: string; id: string } | null;
        }>;
    } | null;
};

export type CurationListWithProductCountQueryVariables = GQLTypes.Exact<{
    filter?: GQLTypes.InputMaybe<GQLTypes.HierarchyCurationListFilterType>;
    pageSize?: GQLTypes.InputMaybe<GQLTypes.Scalars["Int"]["input"]>;
}>;

export type CurationListWithProductCountQuery = {
    __typename: "Queries";
    hierarchyCurationList: {
        __typename: "HierarchyCurationListType";
        nodes: Array<{
            __typename: "CurationContentItemCuration";
            productCount: number;
            name: string;
            id: string;
            slug: string;
            hierarchyId: string;
            heroImageAsset: { __typename: "Asset"; uri: string; id: string } | null;
        }>;
    } | null;
};

export type CurationProductsQueryVariables = GQLTypes.Exact<{
    curationId: GQLTypes.Scalars["String"]["input"];
    pageSize?: GQLTypes.InputMaybe<GQLTypes.Scalars["Int"]["input"]>;
}>;

export type CurationProductsQuery = {
    __typename: "Queries";
    productList: {
        __typename: "ProductList";
        nodes: Array<{
            __typename: "ProductListItem";
            id: string;
            name: string | null;
            slug: string | null;
            isFeatured: boolean | null;
            promotionType: string | null;
            guidePriceType: GQLTypes.ProductGuidePriceType | null;
            reviewCount: number | null;
            reviewRating: number | null;
            isFavourite: boolean | null;
            previewImage: { __typename: "ProductImage"; id: string } | null;
            holibobGuidePrice: { __typename: "Price"; pricingData: any | null; gross: number; currency: string } | null;
            metaList: {
                __typename: "ProductMetaListResponse";
                nodes: Array<{ __typename: "ProductMetaListItem"; type: string | null; value: string | null }>;
            };
            banner: { __typename: "BannerType"; text: string; backgroundColor: string } | null;
            cancellationPolicy: { __typename: "ProductCancellationPolicy"; hasFreeCancellation: boolean | null };
        }>;
    } | null;
};

export type DistributionChannelPageContentQueryVariables = GQLTypes.Exact<{
    path: GQLTypes.Scalars["String"]["input"];
    type: GQLTypes.DistributionChannelPageTypeType;
}>;

export type DistributionChannelPageContentQuery = {
    __typename: "Queries";
    distributionChannelPageContent: {
        __typename: "DistributionChannelPageContent";
        placeId: string | null;
        metaTitle: string | null;
        imageAsset: { __typename: "Asset"; uri: string } | null;
    } | null;
};

export type PaymentIntentQueryVariables = GQLTypes.Exact<{
    bookingSelector: GQLTypes.BookingSelector;
}>;

export type PaymentIntentQuery = {
    __typename: "Queries";
    stripePaymentIntent: {
        __typename: "StripePaymentIntent";
        id: string;
        amount: string;
        clientSecret: string;
        apiKey: string;
        createdAt: string;
    } | null;
};

export type ProductQueryVariables = GQLTypes.Exact<{
    id?: GQLTypes.InputMaybe<GQLTypes.Scalars["String"]["input"]>;
    code?: GQLTypes.InputMaybe<GQLTypes.Scalars["String"]["input"]>;
    slug?: GQLTypes.InputMaybe<GQLTypes.Scalars["String"]["input"]>;
}>;

export type ProductQuery = {
    __typename: "Queries";
    product: {
        __typename: "Product";
        id: string;
        name: string | null;
        isOffSale: boolean | null;
        isFavourite: boolean | null;
        availabilityType: GQLTypes.AvailabilityType;
        description: string | null;
        descriptionJson: any | null;
        isFeatured: boolean | null;
        promotionType: string | null;
        minDuration: string | null;
        maxDuration: string | null;
        cultureShockLevel: string | null;
        bestTimeToVisit: string | null;
        maxGroupSize: string | null;
        advanceArrivalDuration: string | null;
        guidePrice: number | null;
        guidePriceCurrency: string | null;
        guidePriceType: GQLTypes.ProductGuidePriceType | null;
        reviewCount: number | null;
        reviewRating: number | null;
        type: GQLTypes.ProductTypeType;
        descriptionText: string | null;
        offSaleReasonList: Array<{ __typename: "ProductOffSaleReason"; type: string; description: string } | null>;
        externalWidgetData:
            | { __typename: "ExternalWidgetBokun"; bookingChannelId: string; widgetId: string }
            | { __typename: "ExternalWidgetBookingkit"; widgetId: string; subdomain: string | null }
            | { __typename: "ExternalWidgetFareharbor"; itemId: number; flowId: number; slug: string }
            | { __typename: "ExternalWidgetPeek"; keyId: string; widgetId: string }
            | { __typename: "ExternalWidgetRezdy"; widgetId: string; height: number | null; slug: string }
            | { __typename: "ExternalWidgetTicketinghub"; widgetId: string }
            | { __typename: "ExternalWidgetTravelotopos"; widgetId: string; subdomain: string | null }
            | { __typename: "ExternalWidgetVentrata"; productId: string | null; apiKey: string | null }
            | { __typename: "ExternalWidgetXola"; sellerId: string; experienceId: string }
            | null;
        place: { __typename: "ProductPlace"; cityId: string | null } | null;
        startPlace: {
            __typename: "PlaceNew";
            timeZone: string | null;
            googlePlaceId: string;
            formattedAddress: string;
            mapImageUrl: string;
            geoCoordinate: { __typename: "PlaceNewGeoCoordinate"; latitude: number; longitude: number };
        } | null;
        contentList: {
            __typename: "ProductContentListResponse";
            nodes: Array<{
                __typename: "ProductContent";
                name: string | null;
                description: string | null;
                descriptionJson: any | null;
                type: GQLTypes.ProductContentType;
                ordinalPosition: number | null;
                synchronisationId: string | null;
            }>;
        };
        holibobGuidePrice: { __typename: "Price"; pricingData: any | null; gross: number; currency: string } | null;
        metaList: {
            __typename: "ProductMetaListResponse";
            nodes: Array<{ __typename: "ProductMetaListItem"; type: string | null; value: string | null }>;
        };
        images: Array<{ __typename: "ProductImage"; urlLarge: string }>;
        guideLanguageList: {
            __typename: "GuideLanguageList";
            nodes: Array<{ __typename: "GuideLanguage"; code: string | null; name: string | null }>;
        };
        attributeList: {
            __typename: "ProductAttributeList";
            nodes: Array<{
                __typename: "ProductAttribute";
                description: string | null;
                name: string | null;
                type: string | null;
                level1: string | null;
            }>;
        };
        additionList: {
            __typename: "ProductAdditionList";
            nodes: Array<{
                __typename: "ProductAddition";
                name: string | null;
                description: string | null;
                unitPrice: { __typename: "Price"; currency: string; gross: number } | null;
            }>;
        };
        cancellationPolicy: {
            __typename: "ProductCancellationPolicy";
            isCancellable: boolean | null;
            hasFreeCancellation: boolean | null;
            penaltyList: {
                __typename: "ProductCancellationPolicyPenaltyList";
                nodes: Array<{
                    __typename: "ProductCancellationPenalty";
                    formattedText: string | null;
                    ordinalPosition: number | null;
                }>;
            };
        };
        startTimeList: {
            __typename: "ProductStartTimeList";
            nodes: Array<{
                __typename: "ProductStartTime";
                startTime: string | null;
                pickupTime: string | null;
                duration: string | null;
            }>;
        };
        previewImage: { __typename: "ProductImage"; url: string } | null;
        supplier: {
            __typename: "ProductSupplier";
            id: string;
            name: string;
            companyContactPhone: string | null;
            mainContactEmail: string | null;
            websiteUrl: string | null;
        } | null;
        contactPricingList: {
            __typename: "ProductContactPricingList";
            nodes: Array<{
                __typename: "ProductContactPricing";
                type: GQLTypes.ProductContactPricingTypeEnum;
                priceList: {
                    __typename: "ProductContactPricingPriceList";
                    nodes: Array<{
                        __typename: "ProductContactPricingPrice";
                        amount: number;
                        currency: string;
                        minAge: number | null;
                        maxAge: number | null;
                        name: string;
                        capacity: number | null;
                    }>;
                };
            }>;
        } | null;
        itinerary: {
            __typename: "ProductItinerary";
            itemList: {
                __typename: "ProductItineraryItemList";
                nodes: Array<{
                    __typename: "ProductItineraryItem";
                    id: string;
                    name: string | null;
                    duration: string | null;
                    description: { __typename: "HBML"; enhanced: any } | null;
                    extraInformation: {
                        __typename: "ProductItineraryItemExtraInformation";
                        day: number | null;
                        isAdmissionIncluded: boolean | null;
                        passByWithoutStopping: boolean | null;
                    };
                }>;
            };
        };
        provider: { __typename: "Provider"; name: string } | null;
        sourceInstance: { __typename: "SynchronisationSource"; name: string; sourceName: string | null } | null;
    };
};

export type ProductListQueryVariables = GQLTypes.Exact<{
    filter?: GQLTypes.InputMaybe<GQLTypes.ProductListFilter>;
    sort?: GQLTypes.InputMaybe<GQLTypes.ProductListSort>;
    page?: GQLTypes.InputMaybe<GQLTypes.Scalars["Int"]["input"]>;
    pageSize?: GQLTypes.InputMaybe<GQLTypes.Scalars["Int"]["input"]>;
}>;

export type ProductListQuery = {
    __typename: "Queries";
    productList: {
        __typename: "ProductList";
        pages: number;
        nextPage: number | null;
        recordCount: number;
        pageCount: number;
        previousPage: number | null;
        products: Array<{
            __typename: "ProductListItem";
            id: string;
            code: string | null;
            slug: string | null;
            name: string | null;
            isFeatured: boolean | null;
            isFavourite: boolean | null;
            promotionType: string | null;
            guidePriceType: GQLTypes.ProductGuidePriceType | null;
            reviewRating: number | null;
            reviewCount: number | null;
            type: GQLTypes.ProductTypeType;
            availabilityCount: number | null;
            holibobGuidePrice: { __typename: "Price"; pricingData: any | null; gross: number; currency: string } | null;
            previewImage: { __typename: "ProductImage"; id: string } | null;
            metaList: {
                __typename: "ProductMetaListResponse";
                nodes: Array<{ __typename: "ProductMetaListItem"; type: string | null; value: string | null }>;
            };
            cancellationPolicy: { __typename: "ProductCancellationPolicy"; hasFreeCancellation: boolean | null };
            banner: { __typename: "BannerType"; text: string; backgroundColor: string } | null;
        }>;
    } | null;
};

export type S3SignedDownloadUrlQueryVariables = GQLTypes.Exact<{
    key: GQLTypes.Scalars["String"]["input"];
}>;

export type S3SignedDownloadUrlQuery = {
    __typename: "Queries";
    s3SignedDownloadUrl: { __typename: "S3SignedDownloadUrl"; url: string } | null;
};

export const FontCustomFragmentDoc = gql`
    fragment FontCustom on FontCustom {
        bold {
            uri
            __typename
        }
        boldItalic {
            uri
            __typename
        }
        medium {
            uri
            __typename
        }
        mediumItalic {
            uri
            __typename
        }
        regular {
            uri
            __typename
        }
        regularItalic {
            uri
            __typename
        }
        __typename
    }
`;
export const FontFragmentDoc = gql`
    fragment Font on Font {
        ... on FontExternal {
            __typename
            name
        }
        ... on FontCustom {
            ...FontCustom
            __typename
        }
        __typename
    }
    ${FontCustomFragmentDoc}
`;
export const BrandColourPresetFragmentDoc = gql`
    fragment BrandColourPreset on BrandColourPreset {
        main
        contrast
        __typename
    }
`;
export const BrandForThemeFragmentDoc = gql`
    fragment BrandForTheme on Brand {
        primaryFont
        headerLogoPaddingTop
        headerLogoPaddingBottom
        specialFont {
            ...Font
            __typename
        }
        headerColour {
            ...BrandColourPreset
            __typename
        }
        secondaryColour {
            ...BrandColourPreset
            __typename
        }
        footerColour {
            ...BrandColourPreset
            __typename
        }
        primaryColour {
            ...BrandColourPreset
            __typename
        }
        primaryButtonColour {
            ...BrandColourPreset
            __typename
        }
        secondaryButtonColour {
            ...BrandColourPreset
            __typename
        }
        spotColours {
            shade40
            shade70
            shade100
            shade400
            shade700
            index
            __typename
        }
        airiness
        roundness
        __typename
    }
    ${FontFragmentDoc}
    ${BrandColourPresetFragmentDoc}
`;
export const BookingQuestionFragmentDoc = gql`
    fragment BookingQuestion on BookingQuestion {
        id
        bookingId
        type
        label
        helper
        autoCompleteValue
        placeholder
        dataType
        dataFormat
        defaultValue
        isFromOptions
        isSelectMultiple
        isRequired
        availableOptions {
            label
            value
            __typename
        }
        answerValue
        answerFile
        answerFileAsset {
            url
            __typename
        }
        answerFormattedText
        __typename
    }
`;
export const BookingAvailabilityProductCancellationPolicyFragmentDoc = gql`
    fragment BookingAvailabilityProductCancellationPolicy on ProductCancellationPolicy {
        isCancellable
        penaltyList {
            nodes {
                formattedText
                __typename
            }
            __typename
        }
        __typename
    }
`;
export const BookingAvailabilityProductFragmentDoc = gql`
    fragment BookingAvailabilityProduct on Product {
        id
        name
        availabilityType
        previewImage {
            id
            __typename
        }
        cancellationPolicy {
            ...BookingAvailabilityProductCancellationPolicy
            __typename
        }
        __typename
    }
    ${BookingAvailabilityProductCancellationPolicyFragmentDoc}
`;
export const BookingAvailabilityOptionFragmentDoc = gql`
    fragment BookingAvailabilityOption on BookingAvailabilityOption {
        id
        isAnswered
        label
        level
        helper
        placeholder
        dataType
        answerValue
        answerFormattedText
        availableOptions {
            label
            value
            __typename
        }
        type
        __typename
    }
`;
export const BookingAvailabilityQuestionFragmentDoc = gql`
    fragment BookingAvailabilityQuestion on BookingAvailabilityQuestion {
        id
        bookingId
        label
        helper
        placeholder
        dataType
        dataFormat
        defaultValue
        isFromOptions
        isSelectMultiple
        isRequired
        availableOptions {
            label
            value
            __typename
        }
        answerValue
        answerFile
        answerFileAsset {
            url
            __typename
        }
        answerFormattedText
        autoCompleteValue
        __typename
    }
`;
export const BookingAvailabilityPersonQuestionFragmentDoc = gql`
    fragment BookingAvailabilityPersonQuestion on BookingAvailabilityPersonQuestion {
        id
        bookingId
        label
        helper
        placeholder
        dataType
        dataFormat
        defaultValue
        isFromOptions
        isSelectMultiple
        isRequired
        availableOptions {
            label
            value
            __typename
        }
        answerValue
        answerFile
        answerFileAsset {
            url
            __typename
        }
        answerFormattedText
        autoCompleteValue
        __typename
    }
`;
export const BookingAvailabilityPersonFragmentDoc = gql`
    fragment BookingAvailabilityPerson on BookingAvailabilityPerson {
        id
        pricingCategoryLabel
        isQuestionsComplete
        totalPrice {
            grossFormattedText
            netFormattedText
            __typename
        }
        questionList {
            nodes {
                ...BookingAvailabilityPersonQuestion
                __typename
            }
            __typename
        }
        __typename
    }
    ${BookingAvailabilityPersonQuestionFragmentDoc}
`;
export const BookingAvailabilityFragmentDoc = gql`
    fragment BookingAvailability on BookingAvailability {
        id
        date
        startAt
        createdAt
        type
        timeZone
        productName
        productId
        state: processState
        refreshStatus
        canCancel
        canRevertCancellation
        canForceCancel
        canEdit
        canRevertEdit
        canAmend
        code
        saleCurrency
        bookingId
        originBookingAvailabilityId
        voucherUrl
        isActive
        isConfirmed
        parent {
            refreshStatus
            totalPrice {
                grossFormattedText
                __typename
            }
            __typename
        }
        isQuestionsComplete
        totalPrice {
            grossFormattedText
            netFormattedText
            commissionFormattedText
            gross
            currency
            __typename
        }
        product {
            ...BookingAvailabilityProduct
            __typename
        }
        optionList {
            nodes {
                ...BookingAvailabilityOption
                __typename
            }
            __typename
        }
        questionList {
            nodes {
                ...BookingAvailabilityQuestion
                __typename
            }
            __typename
        }
        personList {
            nodes {
                ...BookingAvailabilityPerson
                __typename
            }
            __typename
        }
        cancellationState {
            effectiveRefundState
            effectiveRefundAmount {
                amount
                formattedText
                __typename
            }
            effectivePenalty {
                type
                relativeTo
                duration
                refundPercentage
                amount
                amountType
                amountCurrency
                formattedText
                __typename
            }
            __typename
        }
        cancellationPolicy {
            penaltyList {
                nodes {
                    formattedText
                    ordinalPosition
                    __typename
                }
                __typename
            }
            isCancellable
            __typename
        }
        originBookingAvailability {
            id
            startAt
            endAt
            createdAt
            timeZone
            type
            productName
            state: processState
            totalPrice {
                grossFormattedText
                __typename
            }
            personList {
                nodes {
                    id
                    pricingCategoryLabel
                    isQuestionsComplete
                    totalPrice {
                        grossFormattedText
                        netFormattedText
                        __typename
                    }
                    questionList {
                        nodes {
                            ...BookingAvailabilityPersonQuestion
                            __typename
                        }
                        __typename
                    }
                    __typename
                }
                __typename
            }
            optionList {
                recordCount
                nodes {
                    ...BookingAvailabilityOption
                    __typename
                }
                __typename
            }
            __typename
        }
        __typename
    }
    ${BookingAvailabilityProductFragmentDoc}
    ${BookingAvailabilityOptionFragmentDoc}
    ${BookingAvailabilityQuestionFragmentDoc}
    ${BookingAvailabilityPersonFragmentDoc}
    ${BookingAvailabilityPersonQuestionFragmentDoc}
`;
export const BookingFragmentDoc = gql`
    fragment Booking on Booking {
        id
        code
        leadPassengerName
        partnerName
        state
        paymentState
        isSandboxed
        isWorkflowProcessing
        workflowInitialisedAt
        isComplete
        reference
        saleCurrency
        canCommit
        isQuestionsComplete
        voucherUrl
        canCancel
        createdAt
        voucherCreatedAt
        bookingUrl
        consumerTrip {
            id
            placeId
            __typename
        }
        emailSummarySentAt
        termAndCondition
        useLifecycleManager
        isPendingCommit
        cancellationEffectiveRefundAmount {
            amount
            currency
            formattedText
            __typename
        }
        contact {
            email
            phone
            name
            __typename
        }
        totalPrice {
            gross
            grossFormattedText
            netFormattedText
            commissionFormattedText
            currency
            __typename
        }
        outstandingConsumerAmount {
            amount
            currency
            formattedText
            __typename
        }
        questionList {
            nodes {
                ...BookingQuestion
                __typename
            }
            __typename
        }
        availabilityList {
            nodes {
                ...BookingAvailability
                __typename
            }
            __typename
        }
        __typename
    }
    ${BookingQuestionFragmentDoc}
    ${BookingAvailabilityFragmentDoc}
`;
export const BookingAvailabilityDomainFragmentDoc = gql`
    fragment BookingAvailabilityDomain on BookingAvailability {
        id
        date
        startAt
        createdAt
        type
        timeZone
        productName
        productId
        state: processState
        refreshStatus
        canCancel
        canRevertCancellation
        canForceCancel
        canEdit
        canRevertEdit
        canAmend
        code
        saleCurrency
        bookingId
        useLifecycleManager
        originBookingAvailabilityId
        voucherUrl
        isActive
        isConfirmed
        parent {
            refreshStatus
            totalPrice {
                grossFormattedText
                __typename
            }
            __typename
        }
        isQuestionsComplete
        totalPrice {
            grossFormattedText
            netFormattedText
            commissionFormattedText
            gross
            currency
            __typename
        }
        product {
            ...BookingAvailabilityProduct
            __typename
        }
        optionList {
            nodes {
                ...BookingAvailabilityOption
                __typename
            }
            __typename
        }
        bookingQuestionList {
            nodes {
                ...BookingQuestion
                __typename
            }
            __typename
        }
        questionList {
            nodes {
                ...BookingAvailabilityQuestion
                __typename
            }
            __typename
        }
        personList {
            nodes {
                ...BookingAvailabilityPerson
                __typename
            }
            __typename
        }
        cancellationState {
            effectiveRefundState
            effectiveRefundAmount {
                amount
                formattedText
                __typename
            }
            effectivePenalty {
                type
                relativeTo
                duration
                refundPercentage
                amount
                amountType
                amountCurrency
                formattedText
                __typename
            }
            __typename
        }
        cancellationPolicy {
            penaltyList {
                nodes {
                    formattedText
                    ordinalPosition
                    __typename
                }
                __typename
            }
            isCancellable
            __typename
        }
        originBookingAvailability {
            id
            startAt
            endAt
            createdAt
            timeZone
            type
            productName
            state: processState
            totalPrice {
                grossFormattedText
                __typename
            }
            personList {
                nodes {
                    id
                    pricingCategoryLabel
                    isQuestionsComplete
                    totalPrice {
                        grossFormattedText
                        netFormattedText
                        __typename
                    }
                    questionList {
                        nodes {
                            ...BookingAvailabilityPersonQuestion
                            __typename
                        }
                        __typename
                    }
                    __typename
                }
                __typename
            }
            optionList {
                recordCount
                nodes {
                    ...BookingAvailabilityOption
                    __typename
                }
                __typename
            }
            __typename
        }
        bookingInfoSummary {
            state
            code
            leadPassengerName
            partnerExternalReference
            __typename
        }
        __typename
    }
    ${BookingAvailabilityProductFragmentDoc}
    ${BookingAvailabilityOptionFragmentDoc}
    ${BookingQuestionFragmentDoc}
    ${BookingAvailabilityQuestionFragmentDoc}
    ${BookingAvailabilityPersonFragmentDoc}
    ${BookingAvailabilityPersonQuestionFragmentDoc}
`;
export const ConsumerTripWeatherForecastFragmentDoc = gql`
    fragment ConsumerTripWeatherForecast on ConsumerTripWeatherForecast {
        date
        type
        temperature {
            unit
            value
            __typename
        }
        __typename
    }
`;
export const ConsumerTripDestinationFragmentDoc = gql`
    fragment ConsumerTripDestination on ConsumerTripDestination {
        name
        image {
            heroImageAssetUrlList {
                uri
                __typename
            }
            __typename
        }
        place {
            name
            __typename
        }
        __typename
    }
`;
export const ConsumerTripContentConsumerTripFragmentDoc = gql`
    fragment ConsumerTripContentConsumerTrip on ConsumerTrip {
        consumerTripId: id
        consumerId
        arrivalDate
        departureDate
        destinationCountryName
        destinationPagePath
        partnerExternalReference
        consumer {
            id
            givenName
            partnerExternalReference
            __typename
        }
        weatherForecast {
            ...ConsumerTripWeatherForecast
            __typename
        }
        destination {
            ...ConsumerTripDestination
            __typename
        }
        __typename
    }
    ${ConsumerTripWeatherForecastFragmentDoc}
    ${ConsumerTripDestinationFragmentDoc}
`;
export const ConsumerTripContentItemProductListItemFragmentDoc = gql`
    fragment ConsumerTripContentItemProductListItem on ConsumerTripContentItemProductList {
        type
        layout
        filter {
            systemTagIdList
            placeId
            systemTag {
                hasAll
                hasUnion
                hasNot
                __typename
            }
            duration {
                start
                end
                __typename
            }
            geoCircle {
                latitude
                longitude
                radius {
                    value
                    unit
                    __typename
                }
                accuracy
                __typename
            }
            startTime {
                start
                end
                __typename
            }
            __typename
        }
        typeParams
        __typename
    }
`;
export const ConsumerTripProductFragmentDoc = gql`
    fragment ConsumerTripProduct on ProductListItem {
        id
        name
        slug
        isFeatured
        promotionType
        guidePriceType
        reviewCount
        reviewRating
        isFavourite
        previewImage {
            id
            __typename
        }
        holibobGuidePrice {
            pricingData
            gross
            currency
            __typename
        }
        metaList {
            nodes {
                type
                value
                __typename
            }
            __typename
        }
        banner {
            text
            backgroundColor
            __typename
        }
        cancellationPolicy {
            hasFreeCancellation
            __typename
        }
        __typename
    }
`;
export const CurationAssociationItemFragmentDoc = gql`
    fragment CurationAssociationItem on CurationAssociationListItem {
        id
        curationId
        curationName
        imageVaultId
        imageSource
        groupName
        __typename
    }
`;
export const CurationContentItemCurationFragmentDoc = gql`
    fragment CurationContentItemCuration on CurationContentItemCuration {
        name
        id
        slug
        hierarchyId
        heroImageAsset {
            uri
            id
            __typename
        }
        __typename
    }
`;
export const CurationContentItemCurationWithProductCountFragmentDoc = gql`
    fragment CurationContentItemCurationWithProductCount on CurationContentItemCuration {
        ...CurationContentItemCuration
        productCount
        __typename
    }
    ${CurationContentItemCurationFragmentDoc}
`;
export const CurationProductFragmentDoc = gql`
    fragment CurationProduct on ProductListItem {
        id
        name
        slug
        isFeatured
        promotionType
        guidePriceType
        reviewCount
        reviewRating
        isFavourite
        previewImage {
            id
            __typename
        }
        holibobGuidePrice {
            pricingData
            gross
            currency
            __typename
        }
        metaList {
            nodes {
                type
                value
                __typename
            }
            __typename
        }
        banner {
            text
            backgroundColor
            __typename
        }
        cancellationPolicy {
            hasFreeCancellation
            __typename
        }
        __typename
    }
`;
export const ExternalWidgetFragmentDoc = gql`
    fragment ExternalWidget on ExternalWidget {
        ... on ExternalWidgetBokun {
            bookingChannelId
            widgetId
            __typename
        }
        ... on ExternalWidgetRezdy {
            widgetId
            height
            slug
            __typename
        }
        ... on ExternalWidgetXola {
            sellerId
            experienceId
            __typename
        }
        ... on ExternalWidgetPeek {
            keyId
            widgetId
            __typename
        }
        ... on ExternalWidgetFareharbor {
            itemId
            flowId
            slug
            __typename
        }
        ... on ExternalWidgetTicketinghub {
            widgetId
            __typename
        }
        __typename
    }
`;
export const ProductContentEntryFragmentDoc = gql`
    fragment ProductContentEntry on ProductContent {
        name
        description(contentType: HTML)
        descriptionJson
        type
        ordinalPosition
        synchronisationId
        __typename
    }
`;
export const ProductPriceFragmentDoc = gql`
    fragment ProductPrice on Price {
        pricingData
        gross
        currency
        __typename
    }
`;
export const ProductItemFragmentDoc = gql`
    fragment ProductItem on ProductListItem {
        id
        code
        slug
        name
        isFeatured
        isFavourite
        promotionType
        guidePriceType
        reviewRating
        reviewCount
        holibobGuidePrice {
            pricingData
            gross
            currency
            __typename
        }
        type
        availabilityCount
        previewImage {
            id
            __typename
        }
        metaList {
            nodes {
                type
                value
                __typename
            }
            __typename
        }
        cancellationPolicy {
            hasFreeCancellation
            __typename
        }
        banner {
            text
            backgroundColor
            __typename
        }
        __typename
    }
`;
export const ProductItineraryItemFragmentDoc = gql`
    fragment ProductItineraryItem on ProductItineraryItem {
        id
        name
        description {
            enhanced
            __typename
        }
        duration
        extraInformation {
            day
            isAdmissionIncluded
            passByWithoutStopping
            __typename
        }
        __typename
    }
`;
export const ProductProviderFragmentDoc = gql`
    fragment ProductProvider on Provider {
        name
        __typename
    }
`;
export const ProductSourceInstanceFragmentDoc = gql`
    fragment ProductSourceInstance on SynchronisationSource {
        name
        sourceName
        __typename
    }
`;
export const ProductStartPlaceFragmentDoc = gql`
    fragment ProductStartPlace on PlaceNew {
        timeZone
        geoCoordinate {
            latitude
            longitude
            __typename
        }
        googlePlaceId
        formattedAddress
        mapImageUrl
        __typename
    }
`;
export const BookingAddAvailabilityDocument = gql`
    mutation bookingAddAvailability($input: BookingAddAvailabilityInputType!, $saleCurrency: String) {
        bookingAddAvailability(input: $input, saleCurrency: $saleCurrency) {
            id
            __typename
        }
    }
`;
export type BookingAddAvailabilityMutationFn = Apollo.MutationFunction<
    BookingAddAvailabilityMutation,
    BookingAddAvailabilityMutationVariables
>;
export function useBookingAddAvailabilityMutation(
    baseOptions?: Apollo.MutationHookOptions<BookingAddAvailabilityMutation, BookingAddAvailabilityMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<BookingAddAvailabilityMutation, BookingAddAvailabilityMutationVariables>(
        BookingAddAvailabilityDocument,
        options
    );
}
export type BookingAddAvailabilityMutationHookResult = ReturnType<typeof useBookingAddAvailabilityMutation>;
export type BookingAddAvailabilityMutationResult = Apollo.MutationResult<BookingAddAvailabilityMutation>;
export type BookingAddAvailabilityMutationOptions = Apollo.BaseMutationOptions<
    BookingAddAvailabilityMutation,
    BookingAddAvailabilityMutationVariables
>;
export const BookingAmendAvailabilityIntentDocument = gql`
    mutation bookingAmendAvailabilityIntent($availabilityId: String!, $newAvailabilityId: String!) {
        bookingAmendAvailabilityIntent(availabilityId: $availabilityId, newAvailabilityId: $newAvailabilityId) {
            id
            __typename
        }
    }
`;
export type BookingAmendAvailabilityIntentMutationFn = Apollo.MutationFunction<
    BookingAmendAvailabilityIntentMutation,
    BookingAmendAvailabilityIntentMutationVariables
>;
export function useBookingAmendAvailabilityIntentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        BookingAmendAvailabilityIntentMutation,
        BookingAmendAvailabilityIntentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<BookingAmendAvailabilityIntentMutation, BookingAmendAvailabilityIntentMutationVariables>(
        BookingAmendAvailabilityIntentDocument,
        options
    );
}
export type BookingAmendAvailabilityIntentMutationHookResult = ReturnType<
    typeof useBookingAmendAvailabilityIntentMutation
>;
export type BookingAmendAvailabilityIntentMutationResult =
    Apollo.MutationResult<BookingAmendAvailabilityIntentMutation>;
export type BookingAmendAvailabilityIntentMutationOptions = Apollo.BaseMutationOptions<
    BookingAmendAvailabilityIntentMutation,
    BookingAmendAvailabilityIntentMutationVariables
>;
export const BookingCancelDocument = gql`
    mutation BookingCancel($input: BookingCancelInput!) {
        bookingCancel(input: $input) {
            id
            __typename
        }
    }
`;
export type BookingCancelMutationFn = Apollo.MutationFunction<BookingCancelMutation, BookingCancelMutationVariables>;
export function useBookingCancelMutation(
    baseOptions?: Apollo.MutationHookOptions<BookingCancelMutation, BookingCancelMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<BookingCancelMutation, BookingCancelMutationVariables>(BookingCancelDocument, options);
}
export type BookingCancelMutationHookResult = ReturnType<typeof useBookingCancelMutation>;
export type BookingCancelMutationResult = Apollo.MutationResult<BookingCancelMutation>;
export type BookingCancelMutationOptions = Apollo.BaseMutationOptions<
    BookingCancelMutation,
    BookingCancelMutationVariables
>;
export const BookingCommitDocument = gql`
    mutation BookingCommit($bookingSelector: BookingSelector!) {
        booking: bookingCommit(bookingSelector: $bookingSelector) {
            id
            state
            __typename
        }
    }
`;
export type BookingCommitMutationFn = Apollo.MutationFunction<BookingCommitMutation, BookingCommitMutationVariables>;
export function useBookingCommitMutation(
    baseOptions?: Apollo.MutationHookOptions<BookingCommitMutation, BookingCommitMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<BookingCommitMutation, BookingCommitMutationVariables>(BookingCommitDocument, options);
}
export type BookingCommitMutationHookResult = ReturnType<typeof useBookingCommitMutation>;
export type BookingCommitMutationResult = Apollo.MutationResult<BookingCommitMutation>;
export type BookingCommitMutationOptions = Apollo.BaseMutationOptions<
    BookingCommitMutation,
    BookingCommitMutationVariables
>;
export const BookingDeleteAvailabilityDocument = gql`
    mutation BookingDeleteAvailability($input: BookingDeleteAvailabilityInputType!) {
        booking: bookingDeleteAvailability(input: $input) {
            id
            __typename
        }
    }
`;
export type BookingDeleteAvailabilityMutationFn = Apollo.MutationFunction<
    BookingDeleteAvailabilityMutation,
    BookingDeleteAvailabilityMutationVariables
>;
export function useBookingDeleteAvailabilityMutation(
    baseOptions?: Apollo.MutationHookOptions<
        BookingDeleteAvailabilityMutation,
        BookingDeleteAvailabilityMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<BookingDeleteAvailabilityMutation, BookingDeleteAvailabilityMutationVariables>(
        BookingDeleteAvailabilityDocument,
        options
    );
}
export type BookingDeleteAvailabilityMutationHookResult = ReturnType<typeof useBookingDeleteAvailabilityMutation>;
export type BookingDeleteAvailabilityMutationResult = Apollo.MutationResult<BookingDeleteAvailabilityMutation>;
export type BookingDeleteAvailabilityMutationOptions = Apollo.BaseMutationOptions<
    BookingDeleteAvailabilityMutation,
    BookingDeleteAvailabilityMutationVariables
>;
export const BookingEditDocument = gql`
    mutation BookingEdit($input: BookingEditInput) {
        booking: bookingEdit(input: $input) {
            id
            name
            state
            partnerId
            code
            isSandboxed
            __typename
        }
    }
`;
export type BookingEditMutationFn = Apollo.MutationFunction<BookingEditMutation, BookingEditMutationVariables>;
export function useBookingEditMutation(
    baseOptions?: Apollo.MutationHookOptions<BookingEditMutation, BookingEditMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<BookingEditMutation, BookingEditMutationVariables>(BookingEditDocument, options);
}
export type BookingEditMutationHookResult = ReturnType<typeof useBookingEditMutation>;
export type BookingEditMutationResult = Apollo.MutationResult<BookingEditMutation>;
export type BookingEditMutationOptions = Apollo.BaseMutationOptions<BookingEditMutation, BookingEditMutationVariables>;
export const BookingQuestionUploadDocument = gql`
    mutation BookingQuestionUpload($bookingId: ID!, $bookingQuestionId: ID!, $contentType: String!) {
        bookingQuestionUpload(bookingId: $bookingId, bookingQuestionId: $bookingQuestionId, contentType: $contentType) {
            uploadUrl
            asset {
                id
                uri
                url
                __typename
            }
            __typename
        }
    }
`;
export type BookingQuestionUploadMutationFn = Apollo.MutationFunction<
    BookingQuestionUploadMutation,
    BookingQuestionUploadMutationVariables
>;
export function useBookingQuestionUploadMutation(
    baseOptions?: Apollo.MutationHookOptions<BookingQuestionUploadMutation, BookingQuestionUploadMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<BookingQuestionUploadMutation, BookingQuestionUploadMutationVariables>(
        BookingQuestionUploadDocument,
        options
    );
}
export type BookingQuestionUploadMutationHookResult = ReturnType<typeof useBookingQuestionUploadMutation>;
export type BookingQuestionUploadMutationResult = Apollo.MutationResult<BookingQuestionUploadMutation>;
export type BookingQuestionUploadMutationOptions = Apollo.BaseMutationOptions<
    BookingQuestionUploadMutation,
    BookingQuestionUploadMutationVariables
>;
export const BookingReplaceAvailabilityDocument = gql`
    mutation bookingReplaceAvailability($availabilityId: String!, $newAvailabilityId: String!) {
        bookingReplaceAvailability(availabilityId: $availabilityId, newAvailabilityId: $newAvailabilityId) {
            id
            __typename
        }
    }
`;
export type BookingReplaceAvailabilityMutationFn = Apollo.MutationFunction<
    BookingReplaceAvailabilityMutation,
    BookingReplaceAvailabilityMutationVariables
>;
export function useBookingReplaceAvailabilityMutation(
    baseOptions?: Apollo.MutationHookOptions<
        BookingReplaceAvailabilityMutation,
        BookingReplaceAvailabilityMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<BookingReplaceAvailabilityMutation, BookingReplaceAvailabilityMutationVariables>(
        BookingReplaceAvailabilityDocument,
        options
    );
}
export type BookingReplaceAvailabilityMutationHookResult = ReturnType<typeof useBookingReplaceAvailabilityMutation>;
export type BookingReplaceAvailabilityMutationResult = Apollo.MutationResult<BookingReplaceAvailabilityMutation>;
export type BookingReplaceAvailabilityMutationOptions = Apollo.BaseMutationOptions<
    BookingReplaceAvailabilityMutation,
    BookingReplaceAvailabilityMutationVariables
>;
export const BookingRevertAmendAvailabilityIntentDocument = gql`
    mutation bookingRevertAmendAvailabilityIntent($availabilityId: String!) {
        bookingRevertAmendAvailabilityIntent(availabilityId: $availabilityId) {
            sent
            __typename
        }
    }
`;
export type BookingRevertAmendAvailabilityIntentMutationFn = Apollo.MutationFunction<
    BookingRevertAmendAvailabilityIntentMutation,
    BookingRevertAmendAvailabilityIntentMutationVariables
>;
export function useBookingRevertAmendAvailabilityIntentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        BookingRevertAmendAvailabilityIntentMutation,
        BookingRevertAmendAvailabilityIntentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        BookingRevertAmendAvailabilityIntentMutation,
        BookingRevertAmendAvailabilityIntentMutationVariables
    >(BookingRevertAmendAvailabilityIntentDocument, options);
}
export type BookingRevertAmendAvailabilityIntentMutationHookResult = ReturnType<
    typeof useBookingRevertAmendAvailabilityIntentMutation
>;
export type BookingRevertAmendAvailabilityIntentMutationResult =
    Apollo.MutationResult<BookingRevertAmendAvailabilityIntentMutation>;
export type BookingRevertAmendAvailabilityIntentMutationOptions = Apollo.BaseMutationOptions<
    BookingRevertAmendAvailabilityIntentMutation,
    BookingRevertAmendAvailabilityIntentMutationVariables
>;
export const ConsumerTripAssociateSystemTagListDocument = gql`
    mutation ConsumerTripAssociateSystemTagList($consumerTripId: String!, $systemTagIdList: [String!]!) {
        consumerTripAssociateSystemTagList(consumerTripId: $consumerTripId, systemTagIdList: $systemTagIdList)
    }
`;
export type ConsumerTripAssociateSystemTagListMutationFn = Apollo.MutationFunction<
    ConsumerTripAssociateSystemTagListMutation,
    ConsumerTripAssociateSystemTagListMutationVariables
>;
export function useConsumerTripAssociateSystemTagListMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ConsumerTripAssociateSystemTagListMutation,
        ConsumerTripAssociateSystemTagListMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        ConsumerTripAssociateSystemTagListMutation,
        ConsumerTripAssociateSystemTagListMutationVariables
    >(ConsumerTripAssociateSystemTagListDocument, options);
}
export type ConsumerTripAssociateSystemTagListMutationHookResult = ReturnType<
    typeof useConsumerTripAssociateSystemTagListMutation
>;
export type ConsumerTripAssociateSystemTagListMutationResult =
    Apollo.MutationResult<ConsumerTripAssociateSystemTagListMutation>;
export type ConsumerTripAssociateSystemTagListMutationOptions = Apollo.BaseMutationOptions<
    ConsumerTripAssociateSystemTagListMutation,
    ConsumerTripAssociateSystemTagListMutationVariables
>;
export const ConsumerTripEventCreateDocument = gql`
    mutation ConsumerTripEventCreate($consumerTripId: String!, $type: String!, $meta: JSON!) {
        consumerTripEventCreate(consumerTripId: $consumerTripId, type: $type, meta: $meta)
    }
`;
export type ConsumerTripEventCreateMutationFn = Apollo.MutationFunction<
    ConsumerTripEventCreateMutation,
    ConsumerTripEventCreateMutationVariables
>;
export function useConsumerTripEventCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<ConsumerTripEventCreateMutation, ConsumerTripEventCreateMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ConsumerTripEventCreateMutation, ConsumerTripEventCreateMutationVariables>(
        ConsumerTripEventCreateDocument,
        options
    );
}
export type ConsumerTripEventCreateMutationHookResult = ReturnType<typeof useConsumerTripEventCreateMutation>;
export type ConsumerTripEventCreateMutationResult = Apollo.MutationResult<ConsumerTripEventCreateMutation>;
export type ConsumerTripEventCreateMutationOptions = Apollo.BaseMutationOptions<
    ConsumerTripEventCreateMutation,
    ConsumerTripEventCreateMutationVariables
>;
export const SignalBookingAvailabilityCancellationRevertDocument = gql`
    mutation SignalBookingAvailabilityCancellationRevert($input: GenericBookingAvailabilitySignalInput!) {
        signalBookingAvailabilityCancellationRevert(input: $input) {
            sent
            __typename
        }
    }
`;
export type SignalBookingAvailabilityCancellationRevertMutationFn = Apollo.MutationFunction<
    SignalBookingAvailabilityCancellationRevertMutation,
    SignalBookingAvailabilityCancellationRevertMutationVariables
>;
export function useSignalBookingAvailabilityCancellationRevertMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SignalBookingAvailabilityCancellationRevertMutation,
        SignalBookingAvailabilityCancellationRevertMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        SignalBookingAvailabilityCancellationRevertMutation,
        SignalBookingAvailabilityCancellationRevertMutationVariables
    >(SignalBookingAvailabilityCancellationRevertDocument, options);
}
export type SignalBookingAvailabilityCancellationRevertMutationHookResult = ReturnType<
    typeof useSignalBookingAvailabilityCancellationRevertMutation
>;
export type SignalBookingAvailabilityCancellationRevertMutationResult =
    Apollo.MutationResult<SignalBookingAvailabilityCancellationRevertMutation>;
export type SignalBookingAvailabilityCancellationRevertMutationOptions = Apollo.BaseMutationOptions<
    SignalBookingAvailabilityCancellationRevertMutation,
    SignalBookingAvailabilityCancellationRevertMutationVariables
>;
export const SignalBookingAvailabilityListCancellationIntentDocument = gql`
    mutation SignalBookingAvailabilityListCancellationIntent(
        $input: SignalBookingAvailabilityListCancellationIntentInput!
    ) {
        signalBookingAvailabilityListCancellationIntent(input: $input) {
            sent
            __typename
        }
    }
`;
export type SignalBookingAvailabilityListCancellationIntentMutationFn = Apollo.MutationFunction<
    SignalBookingAvailabilityListCancellationIntentMutation,
    SignalBookingAvailabilityListCancellationIntentMutationVariables
>;
export function useSignalBookingAvailabilityListCancellationIntentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SignalBookingAvailabilityListCancellationIntentMutation,
        SignalBookingAvailabilityListCancellationIntentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        SignalBookingAvailabilityListCancellationIntentMutation,
        SignalBookingAvailabilityListCancellationIntentMutationVariables
    >(SignalBookingAvailabilityListCancellationIntentDocument, options);
}
export type SignalBookingAvailabilityListCancellationIntentMutationHookResult = ReturnType<
    typeof useSignalBookingAvailabilityListCancellationIntentMutation
>;
export type SignalBookingAvailabilityListCancellationIntentMutationResult =
    Apollo.MutationResult<SignalBookingAvailabilityListCancellationIntentMutation>;
export type SignalBookingAvailabilityListCancellationIntentMutationOptions = Apollo.BaseMutationOptions<
    SignalBookingAvailabilityListCancellationIntentMutation,
    SignalBookingAvailabilityListCancellationIntentMutationVariables
>;
export const SignalBookingAvailabilityListForceCancellationIntentDocument = gql`
    mutation SignalBookingAvailabilityListForceCancellationIntent(
        $input: SignalBookingAvailabilityListCancellationIntentInput!
    ) {
        signalBookingAvailabilityListForceCancellationIntent(input: $input) {
            sent
            __typename
        }
    }
`;
export type SignalBookingAvailabilityListForceCancellationIntentMutationFn = Apollo.MutationFunction<
    SignalBookingAvailabilityListForceCancellationIntentMutation,
    SignalBookingAvailabilityListForceCancellationIntentMutationVariables
>;
export function useSignalBookingAvailabilityListForceCancellationIntentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SignalBookingAvailabilityListForceCancellationIntentMutation,
        SignalBookingAvailabilityListForceCancellationIntentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        SignalBookingAvailabilityListForceCancellationIntentMutation,
        SignalBookingAvailabilityListForceCancellationIntentMutationVariables
    >(SignalBookingAvailabilityListForceCancellationIntentDocument, options);
}
export type SignalBookingAvailabilityListForceCancellationIntentMutationHookResult = ReturnType<
    typeof useSignalBookingAvailabilityListForceCancellationIntentMutation
>;
export type SignalBookingAvailabilityListForceCancellationIntentMutationResult =
    Apollo.MutationResult<SignalBookingAvailabilityListForceCancellationIntentMutation>;
export type SignalBookingAvailabilityListForceCancellationIntentMutationOptions = Apollo.BaseMutationOptions<
    SignalBookingAvailabilityListForceCancellationIntentMutation,
    SignalBookingAvailabilityListForceCancellationIntentMutationVariables
>;
export const AvailabilityDatePaxListDocument = gql`
    query AvailabilityDatePaxList(
        $productId: String!
        $optionList: [AvailabilityListOptionListItemInput]
        $sessionId: ID
    ) {
        availabilityList(productId: $productId, optionList: $optionList, sessionId: $sessionId) {
            nodes {
                id
                __typename
            }
            __typename
        }
    }
`;
export function useAvailabilityDatePaxListQuery(
    baseOptions: Apollo.QueryHookOptions<AvailabilityDatePaxListQuery, AvailabilityDatePaxListQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AvailabilityDatePaxListQuery, AvailabilityDatePaxListQueryVariables>(
        AvailabilityDatePaxListDocument,
        options
    );
}
export function useAvailabilityDatePaxListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AvailabilityDatePaxListQuery, AvailabilityDatePaxListQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AvailabilityDatePaxListQuery, AvailabilityDatePaxListQueryVariables>(
        AvailabilityDatePaxListDocument,
        options
    );
}
export type AvailabilityDatePaxListQueryHookResult = ReturnType<typeof useAvailabilityDatePaxListQuery>;
export type AvailabilityDatePaxListLazyQueryHookResult = ReturnType<typeof useAvailabilityDatePaxListLazyQuery>;
export type AvailabilityDatePaxListQueryResult = Apollo.QueryResult<
    AvailabilityDatePaxListQuery,
    AvailabilityDatePaxListQueryVariables
>;
export const AvailabilityHotelListDocument = gql`
    query AvailabilityHotelList($productId: String!, $filter: AvailabilityListFilter) {
        availabilityList(productId: $productId, filter: $filter) {
            total
            nodes {
                id
                date
                endDate
                soldOut
                __typename
            }
            __typename
        }
    }
`;
export function useAvailabilityHotelListQuery(
    baseOptions: Apollo.QueryHookOptions<AvailabilityHotelListQuery, AvailabilityHotelListQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AvailabilityHotelListQuery, AvailabilityHotelListQueryVariables>(
        AvailabilityHotelListDocument,
        options
    );
}
export function useAvailabilityHotelListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AvailabilityHotelListQuery, AvailabilityHotelListQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AvailabilityHotelListQuery, AvailabilityHotelListQueryVariables>(
        AvailabilityHotelListDocument,
        options
    );
}
export type AvailabilityHotelListQueryHookResult = ReturnType<typeof useAvailabilityHotelListQuery>;
export type AvailabilityHotelListLazyQueryHookResult = ReturnType<typeof useAvailabilityHotelListLazyQuery>;
export type AvailabilityHotelListQueryResult = Apollo.QueryResult<
    AvailabilityHotelListQuery,
    AvailabilityHotelListQueryVariables
>;
export const BookingDocument = gql`
    query Booking($id: String!, $input: BookingInput) {
        booking(id: $id, input: $input) {
            ...Booking
            __typename
        }
    }
    ${BookingFragmentDoc}
`;
export function useBookingQuery(baseOptions: Apollo.QueryHookOptions<BookingQuery, BookingQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<BookingQuery, BookingQueryVariables>(BookingDocument, options);
}
export function useBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingQuery, BookingQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<BookingQuery, BookingQueryVariables>(BookingDocument, options);
}
export type BookingQueryHookResult = ReturnType<typeof useBookingQuery>;
export type BookingLazyQueryHookResult = ReturnType<typeof useBookingLazyQuery>;
export type BookingQueryResult = Apollo.QueryResult<BookingQuery, BookingQueryVariables>;
export const BookingAvailabilityDomainDocument = gql`
    query BookingAvailabilityDomain($id: String!) {
        bookingAvailability(id: $id) {
            ...BookingAvailabilityDomain
            __typename
        }
    }
    ${BookingAvailabilityDomainFragmentDoc}
`;
export function useBookingAvailabilityDomainQuery(
    baseOptions: Apollo.QueryHookOptions<BookingAvailabilityDomainQuery, BookingAvailabilityDomainQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<BookingAvailabilityDomainQuery, BookingAvailabilityDomainQueryVariables>(
        BookingAvailabilityDomainDocument,
        options
    );
}
export function useBookingAvailabilityDomainLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<BookingAvailabilityDomainQuery, BookingAvailabilityDomainQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<BookingAvailabilityDomainQuery, BookingAvailabilityDomainQueryVariables>(
        BookingAvailabilityDomainDocument,
        options
    );
}
export type BookingAvailabilityDomainQueryHookResult = ReturnType<typeof useBookingAvailabilityDomainQuery>;
export type BookingAvailabilityDomainLazyQueryHookResult = ReturnType<typeof useBookingAvailabilityDomainLazyQuery>;
export type BookingAvailabilityDomainQueryResult = Apollo.QueryResult<
    BookingAvailabilityDomainQuery,
    BookingAvailabilityDomainQueryVariables
>;
export const ConsumerTripDocument = gql`
    query consumerTrip($consumerTripId: ID!) {
        consumerTrip(id: $consumerTripId) {
            consumerTripId: id
            consumerId
            arrivalDate
            departureDate
            destinationCountryName
            destinationPagePath
            partnerExternalReference
            consumer {
                id
                givenName
                partnerExternalReference
                __typename
            }
            weatherForecast {
                ...ConsumerTripWeatherForecast
                __typename
            }
            destination {
                ...ConsumerTripDestination
                __typename
            }
            __typename
        }
    }
    ${ConsumerTripWeatherForecastFragmentDoc}
    ${ConsumerTripDestinationFragmentDoc}
`;
export function useConsumerTripQuery(
    baseOptions: Apollo.QueryHookOptions<ConsumerTripQuery, ConsumerTripQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ConsumerTripQuery, ConsumerTripQueryVariables>(ConsumerTripDocument, options);
}
export function useConsumerTripLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ConsumerTripQuery, ConsumerTripQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ConsumerTripQuery, ConsumerTripQueryVariables>(ConsumerTripDocument, options);
}
export type ConsumerTripQueryHookResult = ReturnType<typeof useConsumerTripQuery>;
export type ConsumerTripLazyQueryHookResult = ReturnType<typeof useConsumerTripLazyQuery>;
export type ConsumerTripQueryResult = Apollo.QueryResult<ConsumerTripQuery, ConsumerTripQueryVariables>;
export const ConsumerTripContentDocument = gql`
    query ConsumerTripContent($consumerTripId: String!, $placeId: String!) {
        consumerTripContent(consumerTripId: $consumerTripId, placeId: $placeId) {
            itemList {
                ...ConsumerTripContentItemProductListItem
                __typename
            }
            systemTagIdList
            __typename
        }
    }
    ${ConsumerTripContentItemProductListItemFragmentDoc}
`;
export function useConsumerTripContentQuery(
    baseOptions: Apollo.QueryHookOptions<ConsumerTripContentQuery, ConsumerTripContentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ConsumerTripContentQuery, ConsumerTripContentQueryVariables>(
        ConsumerTripContentDocument,
        options
    );
}
export function useConsumerTripContentLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ConsumerTripContentQuery, ConsumerTripContentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ConsumerTripContentQuery, ConsumerTripContentQueryVariables>(
        ConsumerTripContentDocument,
        options
    );
}
export type ConsumerTripContentQueryHookResult = ReturnType<typeof useConsumerTripContentQuery>;
export type ConsumerTripContentLazyQueryHookResult = ReturnType<typeof useConsumerTripContentLazyQuery>;
export type ConsumerTripContentQueryResult = Apollo.QueryResult<
    ConsumerTripContentQuery,
    ConsumerTripContentQueryVariables
>;
export const ConsumerTripProductListDocument = gql`
    query ConsumerTripProductList($filter: ProductListFilter, $pageSize: Int = 50) {
        productList(filter: $filter, pageSize: $pageSize, sort: { qualityMatchScore: desc }) {
            nodes {
                ...ConsumerTripProduct
                __typename
            }
            __typename
        }
    }
    ${ConsumerTripProductFragmentDoc}
`;
export function useConsumerTripProductListQuery(
    baseOptions?: Apollo.QueryHookOptions<ConsumerTripProductListQuery, ConsumerTripProductListQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ConsumerTripProductListQuery, ConsumerTripProductListQueryVariables>(
        ConsumerTripProductListDocument,
        options
    );
}
export function useConsumerTripProductListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ConsumerTripProductListQuery, ConsumerTripProductListQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ConsumerTripProductListQuery, ConsumerTripProductListQueryVariables>(
        ConsumerTripProductListDocument,
        options
    );
}
export type ConsumerTripProductListQueryHookResult = ReturnType<typeof useConsumerTripProductListQuery>;
export type ConsumerTripProductListLazyQueryHookResult = ReturnType<typeof useConsumerTripProductListLazyQuery>;
export type ConsumerTripProductListQueryResult = Apollo.QueryResult<
    ConsumerTripProductListQuery,
    ConsumerTripProductListQueryVariables
>;
export const ConsumerTripRecentlyViewedProductListDocument = gql`
    query ConsumerTripRecentlyViewedProductList($consumerTripId: String!) {
        consumerTripRecentlyViewedProductList(consumerTripId: $consumerTripId) {
            nodes {
                ...ConsumerTripProduct
                __typename
            }
            __typename
        }
    }
    ${ConsumerTripProductFragmentDoc}
`;
export function useConsumerTripRecentlyViewedProductListQuery(
    baseOptions: Apollo.QueryHookOptions<
        ConsumerTripRecentlyViewedProductListQuery,
        ConsumerTripRecentlyViewedProductListQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ConsumerTripRecentlyViewedProductListQuery,
        ConsumerTripRecentlyViewedProductListQueryVariables
    >(ConsumerTripRecentlyViewedProductListDocument, options);
}
export function useConsumerTripRecentlyViewedProductListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ConsumerTripRecentlyViewedProductListQuery,
        ConsumerTripRecentlyViewedProductListQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ConsumerTripRecentlyViewedProductListQuery,
        ConsumerTripRecentlyViewedProductListQueryVariables
    >(ConsumerTripRecentlyViewedProductListDocument, options);
}
export type ConsumerTripRecentlyViewedProductListQueryHookResult = ReturnType<
    typeof useConsumerTripRecentlyViewedProductListQuery
>;
export type ConsumerTripRecentlyViewedProductListLazyQueryHookResult = ReturnType<
    typeof useConsumerTripRecentlyViewedProductListLazyQuery
>;
export type ConsumerTripRecentlyViewedProductListQueryResult = Apollo.QueryResult<
    ConsumerTripRecentlyViewedProductListQuery,
    ConsumerTripRecentlyViewedProductListQueryVariables
>;
export const ConsumerTripRecommendedProductListDocument = gql`
    query ConsumerTripRecommendedProductList($consumerTripId: ID!, $placeId: ID!) {
        consumerTripRecommendedProductList(consumerTripId: $consumerTripId, placeId: $placeId) {
            nodes {
                ...ConsumerTripProduct
                __typename
            }
            __typename
        }
    }
    ${ConsumerTripProductFragmentDoc}
`;
export function useConsumerTripRecommendedProductListQuery(
    baseOptions: Apollo.QueryHookOptions<
        ConsumerTripRecommendedProductListQuery,
        ConsumerTripRecommendedProductListQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ConsumerTripRecommendedProductListQuery, ConsumerTripRecommendedProductListQueryVariables>(
        ConsumerTripRecommendedProductListDocument,
        options
    );
}
export function useConsumerTripRecommendedProductListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ConsumerTripRecommendedProductListQuery,
        ConsumerTripRecommendedProductListQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ConsumerTripRecommendedProductListQuery,
        ConsumerTripRecommendedProductListQueryVariables
    >(ConsumerTripRecommendedProductListDocument, options);
}
export type ConsumerTripRecommendedProductListQueryHookResult = ReturnType<
    typeof useConsumerTripRecommendedProductListQuery
>;
export type ConsumerTripRecommendedProductListLazyQueryHookResult = ReturnType<
    typeof useConsumerTripRecommendedProductListLazyQuery
>;
export type ConsumerTripRecommendedProductListQueryResult = Apollo.QueryResult<
    ConsumerTripRecommendedProductListQuery,
    ConsumerTripRecommendedProductListQueryVariables
>;
export const CurationAndCurationProductsDocument = gql`
    query CurationAndCurationProducts($curationId: String!, $pageSize: Int = 50, $includeProducts: Boolean = false) {
        productList(filter: { curationId: $curationId }, pageSize: $pageSize) @include(if: $includeProducts) {
            nodes {
                ...CurationProduct
                __typename
            }
            __typename
        }
        hierarchyCurationList(filter: { curationIdList: [$curationId] }) {
            nodes {
                ...CurationContentItemCuration
                __typename
            }
            __typename
        }
    }
    ${CurationProductFragmentDoc}
    ${CurationContentItemCurationFragmentDoc}
`;
export function useCurationAndCurationProductsQuery(
    baseOptions: Apollo.QueryHookOptions<CurationAndCurationProductsQuery, CurationAndCurationProductsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CurationAndCurationProductsQuery, CurationAndCurationProductsQueryVariables>(
        CurationAndCurationProductsDocument,
        options
    );
}
export function useCurationAndCurationProductsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CurationAndCurationProductsQuery,
        CurationAndCurationProductsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CurationAndCurationProductsQuery, CurationAndCurationProductsQueryVariables>(
        CurationAndCurationProductsDocument,
        options
    );
}
export type CurationAndCurationProductsQueryHookResult = ReturnType<typeof useCurationAndCurationProductsQuery>;
export type CurationAndCurationProductsLazyQueryHookResult = ReturnType<typeof useCurationAndCurationProductsLazyQuery>;
export type CurationAndCurationProductsQueryResult = Apollo.QueryResult<
    CurationAndCurationProductsQuery,
    CurationAndCurationProductsQueryVariables
>;
export const CurationContentLegacyDocument = gql`
    query CurationContentLegacy($id: String!) {
        curationContentLegacy(curationId: $id) {
            curation {
                id
                name
                slug
                heroImageAsset {
                    id
                    __typename
                }
                productCount
                __typename
            }
            children {
                ...CurationContentItemCuration
                __typename
            }
            __typename
        }
    }
    ${CurationContentItemCurationFragmentDoc}
`;
export function useCurationContentLegacyQuery(
    baseOptions: Apollo.QueryHookOptions<CurationContentLegacyQuery, CurationContentLegacyQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CurationContentLegacyQuery, CurationContentLegacyQueryVariables>(
        CurationContentLegacyDocument,
        options
    );
}
export function useCurationContentLegacyLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CurationContentLegacyQuery, CurationContentLegacyQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CurationContentLegacyQuery, CurationContentLegacyQueryVariables>(
        CurationContentLegacyDocument,
        options
    );
}
export type CurationContentLegacyQueryHookResult = ReturnType<typeof useCurationContentLegacyQuery>;
export type CurationContentLegacyLazyQueryHookResult = ReturnType<typeof useCurationContentLegacyLazyQuery>;
export type CurationContentLegacyQueryResult = Apollo.QueryResult<
    CurationContentLegacyQuery,
    CurationContentLegacyQueryVariables
>;
export const CurationContentLegacyWithProductCountDocument = gql`
    query CurationContentLegacyWithProductCount($id: String!) {
        curationContentLegacy(curationId: $id) {
            children {
                ...CurationContentItemCurationWithProductCount
                __typename
            }
            __typename
        }
    }
    ${CurationContentItemCurationWithProductCountFragmentDoc}
`;
export function useCurationContentLegacyWithProductCountQuery(
    baseOptions: Apollo.QueryHookOptions<
        CurationContentLegacyWithProductCountQuery,
        CurationContentLegacyWithProductCountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        CurationContentLegacyWithProductCountQuery,
        CurationContentLegacyWithProductCountQueryVariables
    >(CurationContentLegacyWithProductCountDocument, options);
}
export function useCurationContentLegacyWithProductCountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CurationContentLegacyWithProductCountQuery,
        CurationContentLegacyWithProductCountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        CurationContentLegacyWithProductCountQuery,
        CurationContentLegacyWithProductCountQueryVariables
    >(CurationContentLegacyWithProductCountDocument, options);
}
export type CurationContentLegacyWithProductCountQueryHookResult = ReturnType<
    typeof useCurationContentLegacyWithProductCountQuery
>;
export type CurationContentLegacyWithProductCountLazyQueryHookResult = ReturnType<
    typeof useCurationContentLegacyWithProductCountLazyQuery
>;
export type CurationContentLegacyWithProductCountQueryResult = Apollo.QueryResult<
    CurationContentLegacyWithProductCountQuery,
    CurationContentLegacyWithProductCountQueryVariables
>;
export const CurationListDocument = gql`
    query CurationList($filter: HierarchyCurationListFilterType, $pageSize: Int = 50) {
        hierarchyCurationList(filter: $filter, pageSize: $pageSize) {
            nodes {
                ...CurationContentItemCuration
                __typename
            }
            __typename
        }
    }
    ${CurationContentItemCurationFragmentDoc}
`;
export function useCurationListQuery(
    baseOptions?: Apollo.QueryHookOptions<CurationListQuery, CurationListQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CurationListQuery, CurationListQueryVariables>(CurationListDocument, options);
}
export function useCurationListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CurationListQuery, CurationListQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CurationListQuery, CurationListQueryVariables>(CurationListDocument, options);
}
export type CurationListQueryHookResult = ReturnType<typeof useCurationListQuery>;
export type CurationListLazyQueryHookResult = ReturnType<typeof useCurationListLazyQuery>;
export type CurationListQueryResult = Apollo.QueryResult<CurationListQuery, CurationListQueryVariables>;
export const CurationListWithProductCountDocument = gql`
    query CurationListWithProductCount($filter: HierarchyCurationListFilterType, $pageSize: Int = 50) {
        hierarchyCurationList(filter: $filter, pageSize: $pageSize) {
            nodes {
                ...CurationContentItemCurationWithProductCount
                __typename
            }
            __typename
        }
    }
    ${CurationContentItemCurationWithProductCountFragmentDoc}
`;
export function useCurationListWithProductCountQuery(
    baseOptions?: Apollo.QueryHookOptions<CurationListWithProductCountQuery, CurationListWithProductCountQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CurationListWithProductCountQuery, CurationListWithProductCountQueryVariables>(
        CurationListWithProductCountDocument,
        options
    );
}
export function useCurationListWithProductCountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CurationListWithProductCountQuery,
        CurationListWithProductCountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CurationListWithProductCountQuery, CurationListWithProductCountQueryVariables>(
        CurationListWithProductCountDocument,
        options
    );
}
export type CurationListWithProductCountQueryHookResult = ReturnType<typeof useCurationListWithProductCountQuery>;
export type CurationListWithProductCountLazyQueryHookResult = ReturnType<
    typeof useCurationListWithProductCountLazyQuery
>;
export type CurationListWithProductCountQueryResult = Apollo.QueryResult<
    CurationListWithProductCountQuery,
    CurationListWithProductCountQueryVariables
>;
export const CurationProductsDocument = gql`
    query CurationProducts($curationId: String!, $pageSize: Int = 50) {
        productList(filter: { curationId: $curationId }, pageSize: $pageSize) {
            nodes {
                ...CurationProduct
                __typename
            }
            __typename
        }
    }
    ${CurationProductFragmentDoc}
`;
export function useCurationProductsQuery(
    baseOptions: Apollo.QueryHookOptions<CurationProductsQuery, CurationProductsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CurationProductsQuery, CurationProductsQueryVariables>(CurationProductsDocument, options);
}
export function useCurationProductsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CurationProductsQuery, CurationProductsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CurationProductsQuery, CurationProductsQueryVariables>(
        CurationProductsDocument,
        options
    );
}
export type CurationProductsQueryHookResult = ReturnType<typeof useCurationProductsQuery>;
export type CurationProductsLazyQueryHookResult = ReturnType<typeof useCurationProductsLazyQuery>;
export type CurationProductsQueryResult = Apollo.QueryResult<CurationProductsQuery, CurationProductsQueryVariables>;
export const DistributionChannelPageContentDocument = gql`
    query DistributionChannelPageContent($path: String!, $type: DistributionChannelPageTypeType!) {
        distributionChannelPageContent(path: $path, type: $type) {
            placeId
            metaTitle
            imageAsset {
                uri
                __typename
            }
            __typename
        }
    }
`;
export function useDistributionChannelPageContentQuery(
    baseOptions: Apollo.QueryHookOptions<
        DistributionChannelPageContentQuery,
        DistributionChannelPageContentQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<DistributionChannelPageContentQuery, DistributionChannelPageContentQueryVariables>(
        DistributionChannelPageContentDocument,
        options
    );
}
export function useDistributionChannelPageContentLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        DistributionChannelPageContentQuery,
        DistributionChannelPageContentQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<DistributionChannelPageContentQuery, DistributionChannelPageContentQueryVariables>(
        DistributionChannelPageContentDocument,
        options
    );
}
export type DistributionChannelPageContentQueryHookResult = ReturnType<typeof useDistributionChannelPageContentQuery>;
export type DistributionChannelPageContentLazyQueryHookResult = ReturnType<
    typeof useDistributionChannelPageContentLazyQuery
>;
export type DistributionChannelPageContentQueryResult = Apollo.QueryResult<
    DistributionChannelPageContentQuery,
    DistributionChannelPageContentQueryVariables
>;
export const PaymentIntentDocument = gql`
    query PaymentIntent($bookingSelector: BookingSelector!) {
        stripePaymentIntent(bookingSelector: $bookingSelector) {
            id
            amount
            clientSecret
            apiKey
            createdAt
            __typename
        }
    }
`;
export function usePaymentIntentQuery(
    baseOptions: Apollo.QueryHookOptions<PaymentIntentQuery, PaymentIntentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PaymentIntentQuery, PaymentIntentQueryVariables>(PaymentIntentDocument, options);
}
export function usePaymentIntentLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PaymentIntentQuery, PaymentIntentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PaymentIntentQuery, PaymentIntentQueryVariables>(PaymentIntentDocument, options);
}
export type PaymentIntentQueryHookResult = ReturnType<typeof usePaymentIntentQuery>;
export type PaymentIntentLazyQueryHookResult = ReturnType<typeof usePaymentIntentLazyQuery>;
export type PaymentIntentQueryResult = Apollo.QueryResult<PaymentIntentQuery, PaymentIntentQueryVariables>;
export const ProductDocument = gql`
    query Product($id: String, $code: String, $slug: String) {
        product(id: $id, code: $code, slug: $slug) {
            id
            name
            isOffSale
            isFavourite
            offSaleReasonList {
                type
                description
                __typename
            }
            availabilityType
            externalWidgetData {
                ... on ExternalWidgetBokun {
                    bookingChannelId
                    widgetId
                    __typename
                }
                ... on ExternalWidgetRezdy {
                    widgetId
                    height
                    slug
                    __typename
                }
                ... on ExternalWidgetXola {
                    sellerId
                    experienceId
                    __typename
                }
                ... on ExternalWidgetPeek {
                    keyId
                    widgetId
                    __typename
                }
                ... on ExternalWidgetFareharbor {
                    itemId
                    flowId
                    slug
                    __typename
                }
                ... on ExternalWidgetTicketinghub {
                    widgetId
                    __typename
                }
                ... on ExternalWidgetBookingkit {
                    widgetId
                    subdomain
                    __typename
                }
                ... on ExternalWidgetTravelotopos {
                    widgetId
                    subdomain
                    __typename
                }
                ... on ExternalWidgetVentrata {
                    productId
                    apiKey
                    __typename
                }
                __typename
            }
            description(contentType: HTML)
            descriptionText: description(contentType: TEXT)
            descriptionJson
            isFeatured
            promotionType
            minDuration
            maxDuration
            cultureShockLevel
            bestTimeToVisit
            maxGroupSize
            advanceArrivalDuration
            guidePrice
            guidePriceCurrency
            guidePriceType
            place {
                cityId
                __typename
            }
            startPlace {
                ...ProductStartPlace
                __typename
            }
            contentList {
                nodes {
                    ...ProductContentEntry
                    __typename
                }
                __typename
            }
            holibobGuidePrice {
                ...ProductPrice
                __typename
            }
            metaList {
                nodes {
                    type
                    value
                    __typename
                }
                __typename
            }
            images {
                urlLarge
                __typename
            }
            guideLanguageList {
                nodes {
                    code
                    name
                    __typename
                }
                __typename
            }
            attributeList {
                nodes {
                    description
                    name
                    type
                    level1
                    __typename
                }
                __typename
            }
            additionList {
                nodes {
                    name
                    description
                    unitPrice {
                        currency
                        gross
                        __typename
                    }
                    __typename
                }
                __typename
            }
            cancellationPolicy {
                isCancellable
                hasFreeCancellation
                penaltyList {
                    nodes {
                        formattedText
                        ordinalPosition
                        __typename
                    }
                    __typename
                }
                __typename
            }
            guideLanguageList {
                nodes {
                    code
                    name
                    __typename
                }
                __typename
            }
            startTimeList {
                nodes {
                    startTime
                    pickupTime
                    duration
                    __typename
                }
                __typename
            }
            previewImage {
                url
                __typename
            }
            supplier {
                id
                name
                companyContactPhone
                mainContactEmail
                websiteUrl
                __typename
            }
            contactPricingList {
                nodes {
                    type
                    priceList {
                        nodes {
                            amount
                            currency
                            minAge
                            maxAge
                            name
                            capacity
                            __typename
                        }
                        __typename
                    }
                    __typename
                }
                __typename
            }
            reviewCount
            reviewRating
            type
            itinerary {
                itemList {
                    nodes {
                        ...ProductItineraryItem
                        __typename
                    }
                    __typename
                }
                __typename
            }
            provider {
                ...ProductProvider
                __typename
            }
            sourceInstance {
                ...ProductSourceInstance
                __typename
            }
            __typename
        }
    }
    ${ProductStartPlaceFragmentDoc}
    ${ProductContentEntryFragmentDoc}
    ${ProductPriceFragmentDoc}
    ${ProductItineraryItemFragmentDoc}
    ${ProductProviderFragmentDoc}
    ${ProductSourceInstanceFragmentDoc}
`;
export function useProductQuery(baseOptions?: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
}
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
}
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const ProductListDocument = gql`
    query ProductList($filter: ProductListFilter, $sort: ProductListSort, $page: Int, $pageSize: Int) {
        productList(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
            pages
            nextPage
            recordCount
            pageCount
            previousPage
            products: nodes {
                ...ProductItem
                __typename
            }
            __typename
        }
    }
    ${ProductItemFragmentDoc}
`;
export function useProductListQuery(
    baseOptions?: Apollo.QueryHookOptions<ProductListQuery, ProductListQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ProductListQuery, ProductListQueryVariables>(ProductListDocument, options);
}
export function useProductListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ProductListQuery, ProductListQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ProductListQuery, ProductListQueryVariables>(ProductListDocument, options);
}
export type ProductListQueryHookResult = ReturnType<typeof useProductListQuery>;
export type ProductListLazyQueryHookResult = ReturnType<typeof useProductListLazyQuery>;
export type ProductListQueryResult = Apollo.QueryResult<ProductListQuery, ProductListQueryVariables>;
export const S3SignedDownloadUrlDocument = gql`
    query S3SignedDownloadUrl($key: String!) {
        s3SignedDownloadUrl(key: $key) {
            url
            __typename
        }
    }
`;
export function useS3SignedDownloadUrlQuery(
    baseOptions: Apollo.QueryHookOptions<S3SignedDownloadUrlQuery, S3SignedDownloadUrlQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<S3SignedDownloadUrlQuery, S3SignedDownloadUrlQueryVariables>(
        S3SignedDownloadUrlDocument,
        options
    );
}
export function useS3SignedDownloadUrlLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<S3SignedDownloadUrlQuery, S3SignedDownloadUrlQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<S3SignedDownloadUrlQuery, S3SignedDownloadUrlQueryVariables>(
        S3SignedDownloadUrlDocument,
        options
    );
}
export type S3SignedDownloadUrlQueryHookResult = ReturnType<typeof useS3SignedDownloadUrlQuery>;
export type S3SignedDownloadUrlLazyQueryHookResult = ReturnType<typeof useS3SignedDownloadUrlLazyQuery>;
export type S3SignedDownloadUrlQueryResult = Apollo.QueryResult<
    S3SignedDownloadUrlQuery,
    S3SignedDownloadUrlQueryVariables
>;
